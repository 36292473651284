import { useEffect, useState } from 'react';
import { Data, getEntities, getEntitiesByReference, getEntityById } from '../api/api';
import { checkAPIError } from '../services/ErrorService';
import { setInitialEntity } from '../utils/InitialObjectMapper';

type ListHook = (path: string) => [Data[], string];

export const useListAPI: ListHook = (path: string) => {
  const [list, setList] = useState<Data[]>([]);
  const [apiError, setApiError] = useState('');

  useEffect(() => {
    const getList = async () => {
      try {
        const result = await getEntities(path);
        setList(result.data);
      } catch (e) {
        setApiError(checkAPIError(e));
      }
    };

    getList();
  }, [path]);

  return [list, apiError];
};

type EntityHook = (id: string | undefined, path: string) => [Data, string];

export const useEntityByIdAPI: EntityHook = (id: string | undefined, path: string) => {
  const [entity, setEntity] = useState(setInitialEntity(id, {}));
  const [apiError, setApiError] = useState('');

  useEffect(() => {
    const getEntity = async () => {
      if (id) {
        await getEntityById(path, id)
          .then(result => {
            setEntity(result.data);
          })
          .catch(error => setApiError(checkAPIError(error)));
      }
    };

    getEntity();
  }, [id, path]);

  return [entity, apiError];
};

type RefHook = (path: string, label: string, value: string | undefined) => [Data[], string, any];

export const useEntitiesByRefAPI: RefHook = (path: string, label: string, value: string | undefined) => {
  const [list, setList] = useState<Data[]>([]);
  const [apiError, setApiError] = useState('');

  useEffect(() => {
    const getEntities = async () => {
      if (label && value) {
        await getEntitiesByReference(path, label, value)
          .then(result => {
            setList(result.data);
          })
          .catch(error => setApiError(checkAPIError(error)));
      }
    };

    getEntities();
  }, [path, label, value]);

  return [list, apiError, setList];
};

export const useSetStateHook: any = (setValue: any, value: any) => {
  useEffect(() => {
    setValue(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
