import React, { useEffect, useRef, useState } from 'react';
import { getEntitiesByLimit, getEntitiesByReferenceAndLimit, LimitedData } from '../../api/api';
import { constructOrderRow, DataRow, OrderRow, TableHeader } from './OrdersTableComponents';
import { Box } from '@primer/react';
import InfiniteScroll from 'react-infinite-scroller';
import CustomLoader from '../CustomLoader';
import { DocumentTitleTranslation, useDocumentTitle } from '../../hooks/useDocumentTitle';

interface TabViewProps {
  status: string;
  statuses: any;
  locations: any;
  errorHandler: any;
  setMessages: any;
  orderBy: string;
}

function OrderTabView(props: TabViewProps) {
  const titleTranslations: DocumentTitleTranslation = {
    pending: 'Ootel',
    undone: 'Pooleli',
    done: 'Tehtud',
    all: 'Kõik'
  };

  useDocumentTitle(['Tellimused'], titleTranslations);

  const [rows, setRows] = useState(new Array<OrderRow>());
  const [rowCount, setCount] = useState(0);
  const eTag = useRef();
  const timer = useRef(0);

  function loadOrderRows(asof: string, etag?: string) {
    if (props.status === 'all') {
      return getEntitiesByLimit('orders', asof, 50, 'inserted', etag);
    } else if (props.status === 'undone') {
      return getEntitiesByLimit('orders/undone', asof, 50, 'startedWorking', etag);
    } else if (props.status === 'done') {
      return getEntitiesByReferenceAndLimit('orders', 'status', props.status, asof, 50, 'completed', etag);
    }
    return getEntitiesByReferenceAndLimit('orders', 'status', props.status, asof, 50, 'inserted', etag);
  }

  async function processOrders(result: LimitedData) {
    setCount(Number(result.rowCount));
    return Promise.all(result.rows.map(order => constructOrderRow(order, props.locations, props.statuses)));
  }

  function loadMoreRows(asof: string) {
    return loadOrderRows(asof)
      .then((result: any) => {
        return processOrders(result.data).then(orderRows => {
          setRows([...rows, ...orderRows]);
        });
      })
      .catch(props.errorHandler);
  }

  function loadFirstSetOfData(initial: boolean) {
    loadOrderRows(new Date().toISOString(), eTag.current)
      .then((result: any) => {
        eTag.current = result.headers.etag;

        return processOrders(result.data).then(orderRows => {
          props.setMessages('', '');

          if (initial) {
            setRows(orderRows);
          } else {
            setRows([...rows, ...orderRows]);
            props.setMessages('', 'Tellimuse nimekirja on uuendatud!');
          }

          timer.current = window.setTimeout(() => {
            loadFirstSetOfData(false);
          }, 60000);
        });
      })
      .catch((error: any) => {
        if (error.response && error.response.status === 304) {
          timer.current = window.setTimeout(() => {
            loadFirstSetOfData(false);
          }, 60000);

          return;
        }
        props.errorHandler(error);
      });
  }

  useEffect(() => {
    loadFirstSetOfData(true);

    return function cleanup() {
      window.clearTimeout(timer.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getOffsetValue(rows: any[], field: string): any {
    return rows[rows.length - 1][field];
  }

  return (
    <Box display="flex" flexDirection="column" justifyItems="center">
      <TableHeader />

      {rows.length > 0 && (
        <InfiniteScroll
          pageStart={0}
          loadMore={() => loadMoreRows(getOffsetValue(rows, props.orderBy))}
          hasMore={rowCount > rows.length}
          initialLoad={false}
          loader={<CustomLoader key={getOffsetValue(rows, props.orderBy)} />}>
          {rows.map((row: any, index) => {
            return <DataRow row={row} index={index} key={row.id} />;
          })}
        </InfiniteScroll>
      )}
    </Box>
  );
}

export default OrderTabView;
