import React, { useState } from 'react';
import { useEntitiesByRefAPI, useEntityByIdAPI, useListAPI } from '../hooks/ApiHooks';
import { checkAPIError, handleMultipleErrors } from '../services/ErrorService';
import { getCurrentUserRole } from '../services/CurrentUserService';
import LocationView from '../components/locations/LocationView';
import LocationForm from '../components/locations/LocationForm';
import ContentContainer from '../components/layout/ContentContainer';
import { useParams } from 'react-router';
import PageHead from '../components/layout/PageHead';

function LocationContainer() {
  const { id } = useParams();
  const [error, setError] = useState('');
  const [entity, locationsError] = useEntityByIdAPI(id, 'locations');
  const [customers, customersError] = useListAPI('customers');
  const [files, filesError, setFiles] = useEntitiesByRefAPI('attachments', 'parentId', id);
  const [users, usersError] = useListAPI('users');

  handleMultipleErrors(error, locationsError, setError);
  handleMultipleErrors(error, customersError, setError);
  handleMultipleErrors(error, filesError, setError);
  handleMultipleErrors(error, usersError, setError);

  function apiErrorHandler(error: any): void {
    setError(checkAPIError(error));
    window.scrollTo(0, 0);
  }

  function getContainerTitle() {
    if (getCurrentUserRole() === 'admin') {
      return id ? 'Muuda asukoha andmeid' : 'Lisa uus asukoht';
    } else if (id && entity.j.name) {
      return entity.j.name;
    }

    return 'Asukoht';
  }

  return (
    <>
      {(!entity.i || entity.j.name) && (
        <ContentContainer>
          <PageHead title={getContainerTitle()} errorMsg={error}></PageHead>
          {getCurrentUserRole() === 'admin' && (
            <LocationForm
              entity={entity}
              customers={customers}
              files={files}
              users={users}
              apiErrorHandler={apiErrorHandler}
              setFiles={setFiles}
              setError={setError}
            />
          )}
          {entity.i && getCurrentUserRole() === 'worker' && (
            <LocationView
              entity={entity}
              customers={customers}
              files={files}
              users={users}
              apiErrorHandler={apiErrorHandler}
            />
          )}
        </ContentContainer>
      )}
    </>
  );
}

export default LocationContainer;
