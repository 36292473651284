import React from 'react';
import { Data } from '../../api/api';
import AttachmentGallery from '../uploads/AttachmentGallery';
import DataColumn from '../DataColumn';

function LocationView(props: { entity: Data; customers: Data[]; files: Data[]; users: Data[]; apiErrorHandler: any }) {
  function getClientName(): string {
    const found = props.customers.find(c => c.i === props.entity.j.customerId);

    if (found) {
      return found.j.name;
    }

    return '';
  }

  function getUserName(id: string) {
    const user = props.users.find(u => u.i === id);

    if (user) {
      return `${user.j.firstName} ${user.j.lastName}`;
    }

    return '';
  }

  return (
    <>
      <DataColumn label="Klient" value={getClientName()} />

      <DataColumn label="Aadress" value={props.entity.j.address} />

      <DataColumn label="Telefon" value={props.entity.j.phone} />

      {props.entity.j.workers && (
        <DataColumn label="Töömehed" value={props.entity.j.workers.map((w: string) => getUserName(w)).join(', ')} />
      )}

      {props.files.length > 0 && (
        <AttachmentGallery files={props.files.map(f => f.j)} errorHandler={props.apiErrorHandler} newWindow={true} />
      )}
    </>
  );
}

export default LocationView;
