import React, { useEffect, useState } from 'react';
import { getEntities } from '../api/api';
import 'moment/locale/et';
import { checkAPIError } from '../services/ErrorService';
import { Box, Text, UnderlineNav } from '@primer/react';
import './Orders.css';
import OrderTabView from '../components/orders/OrdersTabView';
import { constructOrderRow, DataRow, OrderRow, TableHeader } from '../components/orders/OrdersTableComponents';
import ContentContainer from '../components/layout/ContentContainer';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import PageHead from '../components/layout/PageHead';

function Orders() {
  const location = useLocation();
  const navigate = useNavigate();

  const [statuses, setStatuses] = useState({});
  const [locations, setLocations] = useState({});
  const [apiError, setApiError] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [searchRows, setSearchRows] = useState<OrderRow[]>([]);
  const [currentStatus, setCurrentStatus] = useState(
    location.hash && location.hash !== '#search' ? location.hash.slice(1) : 'pending'
  );

  useEffect(() => {
    Promise.all([getEntities('order-statuses'), getEntities('locations')])
      .then(([statusRes, locationRes]) => {
        setStatuses(Object.assign({}, ...statusRes.data.map(status => ({ [status.j.status]: status.j.text }))));
        setLocations(Object.assign({}, ...locationRes.data.map(location => ({ [location.i]: location.j.name }))));
      })
      .catch(error => handleErrors(error));
  }, []);

  function setMessages(error: string, success: string) {
    setApiError(error);
    setSuccessMsg(success);
  }

  function isSelected(label: string) {
    return currentStatus === label || undefined;
  }

  function handleStatusChange(status: string) {
    setCurrentStatus(status);
    setMessages('', '');
  }

  function handleErrors(error: any) {
    const err = checkAPIError(error);
    setApiError(err);
  }

  function shouldShowView(status: string) {
    return currentStatus === status && Object.keys(statuses).length > 0 && Object.keys(locations).length > 0;
  }

  function doSearch(input: string) {
    if (input) {
      getEntities('/orders/search', { value: input })
        .then(results => {
          navigate('/orders#search');
          handleStatusChange('search');
          setSearchRows([]);

          if (results.data.length > 0) {
            const rows = results.data.map(d => constructOrderRow(d, locations, statuses));
            setSearchRows(rows);
          }
        })
        .catch(error => handleErrors(error));

      return;
    }

    navigate('/orders#pending');
    handleStatusChange('pending');
  }

  return (
    <ContentContainer xlarge>
      <PageHead
        title="Tellimused"
        add="/orders/add"
        search={doSearch}
        errorMsg={apiError}
        successMsg={successMsg}></PageHead>

      <UnderlineNav aria-label="Orders" sx={{ mb: 3 }}>
        <UnderlineNav.Item
          href="#pending"
          aria-current={isSelected('pending')}
          onSelect={() => handleStatusChange('pending')}>
          Ootel
        </UnderlineNav.Item>
        <UnderlineNav.Item
          href="#undone"
          aria-current={isSelected('undone')}
          onClick={() => handleStatusChange('undone')}>
          Pooleli
        </UnderlineNav.Item>
        <UnderlineNav.Item href="#done" aria-current={isSelected('done')} onClick={() => handleStatusChange('done')}>
          Tehtud
        </UnderlineNav.Item>
        <UnderlineNav.Item href="#all" aria-current={isSelected('all')} onClick={() => handleStatusChange('all')}>
          Kõik
        </UnderlineNav.Item>
      </UnderlineNav>

      {shouldShowView('pending') && (
        <OrderTabView
          status="pending"
          statuses={statuses}
          locations={locations}
          errorHandler={(e: any) => handleErrors(e)}
          setMessages={setMessages}
          orderBy="inserted"
        />
      )}

      {shouldShowView('undone') && (
        <OrderTabView
          status="undone"
          statuses={statuses}
          locations={locations}
          errorHandler={(e: any) => handleErrors(e)}
          setMessages={setMessages}
          orderBy="startedWorking"
        />
      )}

      {shouldShowView('done') && (
        <OrderTabView
          status="done"
          statuses={statuses}
          locations={locations}
          errorHandler={(e: any) => handleErrors(e)}
          setMessages={setMessages}
          orderBy="completed"
        />
      )}

      {shouldShowView('all') && (
        <OrderTabView
          status="all"
          statuses={statuses}
          locations={locations}
          errorHandler={(e: any) => handleErrors(e)}
          setMessages={setMessages}
          orderBy="inserted"
        />
      )}

      {shouldShowView('search') && searchRows.length > 0 && (
        <Box display="flex" flexDirection="column" justifyItems="center">
          <TableHeader />

          {searchRows.map((row: any, index) => {
            return <DataRow row={row} index={index} key={row.id} />;
          })}
        </Box>
      )}

      {shouldShowView('search') && searchRows.length === 0 && (
        <Text my={2}>Otsingule vastavaid tellimusi ei leitud.</Text>
      )}
    </ContentContainer>
  );
}

export default Orders;
