import moment from 'moment';

export function formatDateTimeValue(date: string) {
  return date ? moment(date).format('DD.MM.YYYY HH:mm') : '';
}

export function formatDateValue(date: string) {
  return date ? moment(date).format('DD.MM.YYYY') : '';
}

export function formatTimeValue(date: string) {
  return date ? moment(date).format('HH:mm') : '';
}

export function formatDateTimeDuration(start: string, end?: string) {
  if (!end) {
    return formatDateTimeValue(start);
  }

  if (formatDateValue(start) === formatDateValue(end)) {
    return formatDateTimeValue(start) + ' - ' + formatTimeValue(end);
  }

  return formatDateTimeValue(start) + ' - ' + formatDateTimeValue(end);
}
