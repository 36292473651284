import restTypedAxios from 'restyped-axios';
import { CoreAPI } from 'kiisu-api-types/core';
import {
  DrivingDataNew,
  DrivingDataStored,
  StockItemNew,
  StockItemStored,
  UsedSpareNew,
  UsedSpareStored,
  WorkProgressNew,
  WorkProgressStored
} from 'kiisu-api-types/core.work-data';
import { getBearerToken } from './api';

const coreAPI = restTypedAxios.create<CoreAPI>({
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
});

export function getUsedSpares(orderId?: string) {
  return coreAPI.get<'/core/v2/used-spares'>('/core/v2/used-spares', {
    params: {
      orderId: orderId
    },
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function postUsedSpare(data: UsedSpareNew) {
  return coreAPI.post<'/core/v2/used-spares'>('/core/v2/used-spares', data, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function getUsedSpare(id: string) {
  return coreAPI.get<'/core/v2/used-spares/:id'>(`/core/v2/used-spares/${id}`, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function patchUsedSpare(spare: UsedSpareStored) {
  return coreAPI.patch<'/core/v2/used-spares/:id'>(`/core/v2/used-spares/${spare.metadata.id}`, spare, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function deleteUsedSpare(id: string, resourceVersion: number) {
  return coreAPI.delete<'/core/v2/used-spares/:id/:resourceVersion'>(`/core/v2/used-spares/${id}/${resourceVersion}`, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function getStockItems(orderId?: string) {
  return coreAPI.get<'/core/v2/stock-items'>('/core/v2/stock-items', {
    params: {
      orderId: orderId
    },
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function postStockItem(data: StockItemNew) {
  return coreAPI.post<'/core/v2/stock-items'>('/core/v2/stock-items', data, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function getStockItem(id: string) {
  return coreAPI.get<'/core/v2/stock-items/:id'>(`/core/v2/stock-items/${id}`, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function patchStockItem(item: StockItemStored) {
  return coreAPI.patch<'/core/v2/stock-items/:id'>(`/core/v2/stock-items/${item.metadata.id}`, item, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function deleteStockItem(id: string, resourceVersion: number) {
  return coreAPI.delete<'/core/v2/stock-items/:id/:resourceVersion'>(`/core/v2/stock-items/${id}/${resourceVersion}`, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function getWorkings(orderId?: string) {
  return coreAPI.get<'/core/v2/workings'>('/core/v2/workings', {
    params: {
      orderId: orderId
    },
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function postWorkProgress(data: WorkProgressNew) {
  return coreAPI.post<'/core/v2/workings'>('/core/v2/workings', data, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function getWorkProgress(id: string) {
  return coreAPI.get<'/core/v2/workings/:id'>(`/core/v2/workings/${id}`, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function patchWorkProgress(item: WorkProgressStored) {
  return coreAPI.patch<'/core/v2/workings/:id'>(`/core/v2/workings/${item.metadata.id}`, item, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function deleteWorkProgress(id: string, resourceVersion: number) {
  return coreAPI.delete<'/core/v2/workings/:id/:resourceVersion'>(`/core/v2/workings/${id}/${resourceVersion}`, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function getDrivings(orderId?: string) {
  return coreAPI.get<'/core/v2/drivings'>('/core/v2/drivings', {
    params: {
      orderId: orderId
    },
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function postDrivingData(data: DrivingDataNew) {
  return coreAPI.post<'/core/v2/drivings'>('/core/v2/drivings', data, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function getDrivingData(id: string) {
  return coreAPI.get<'/core/v2/drivings/:id'>(`/core/v2/drivings/${id}`, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function patchDrivingData(item: DrivingDataStored) {
  return coreAPI.patch<'/core/v2/drivings/:id'>(`/core/v2/drivings/${item.metadata.id}`, item, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function deleteDrivingData(id: string, resourceVersion: number) {
  return coreAPI.delete<'/core/v2/drivings/:id/:resourceVersion'>(`/core/v2/drivings/${id}/${resourceVersion}`, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}
