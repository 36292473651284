import { Box, Text } from '@primer/react';
import { Link } from 'react-router-dom';
import React from 'react';
import Moment from 'react-moment';
import { Data } from '../../api/api';

export interface OrderRow {
  id: string;
  name: string;
  referenceNr: string;
  callTime: string;
  inserted: string | undefined;
  startedWorking: string | undefined;
  completed: string | undefined;
  status: string;
}

export function HeaderCell(props: { width: number; display: string | string[]; title: string }) {
  return (
    <Box width={[1 / 3, 1 / 4, 1 / 5, 1 / props.width]} p={1} display={props.display}>
      <Text fontWeight="bold">{props.title}</Text>
    </Box>
  );
}

export function TableCell(props: { width: number; display: string | string[]; truncate?: boolean; children: any }) {
  return (
    <Box width={[1 / 3, 1 / 4, 1 / 5, 1 / props.width]} px={2} py={1} display={props.display}>
      <Text className={props.truncate ? 'css-truncate css-truncate-target' : ''}>{props.children}</Text>
    </Box>
  );
}

export function DataRow(props: { row: OrderRow; index: number }) {
  return (
    <Box
      display="flex"
      flexWrap="nowrap"
      justifyContent="space-around"
      py={2}
      backgroundColor={props.index % 2 === 0 ? 'rgba(0, 0, 0, 0.05)' : 'inherit'}>
      <TableCell width={11} display="block">
        <Link to={'/orders/' + props.row.id}>{props.row.id.substr(0, 8)}</Link>
      </TableCell>

      <TableCell width={9} display="block" truncate>
        {props.row.name}
      </TableCell>

      <TableCell width={8} display={['none', 'none', 'none', 'block']} truncate>
        {props.row.referenceNr}
      </TableCell>

      <TableCell width={8} display={['none', 'block', 'block', 'block']}>
        {props.row.inserted && <Moment format="DD.MM.YYYY HH:mm">{props.row.inserted}</Moment>}
      </TableCell>

      <TableCell width={8} display={['none', 'none', 'block', 'block']}>
        {props.row.callTime && <Moment fromNow>{props.row.callTime}</Moment>}
      </TableCell>

      <TableCell width={8} display={['none', 'none', 'none', 'block']}>
        {props.row.startedWorking && <Moment format="DD.MM.YYYY HH:mm">{props.row.startedWorking}</Moment>}
      </TableCell>

      <TableCell width={8} display={['none', 'none', 'none', 'block']}>
        {props.row.completed && <Moment format="DD.MM.YYYY HH:mm">{props.row.completed}</Moment>}
      </TableCell>

      <TableCell width={9} display="block">
        {props.row.status}
      </TableCell>
    </Box>
  );
}

export function TableHeader() {
  return (
    <Box className="border-bottom rounded-0">
      <Box display="flex" flexWrap="nowrap" justifyContent="space-around" my={2}>
        <HeaderCell width={11} display="block" title="Number" />

        <HeaderCell width={9} display="block" title="Objekt" />

        <HeaderCell width={8} display={['none', 'none', 'none', 'block']} title="Kliendi viide" />

        <HeaderCell width={8} display={['none', 'block', 'block', 'block']} title="Sisestatud" />

        <HeaderCell width={8} display={['none', 'none', 'block', 'block']} title="Väljakutse" />

        <HeaderCell width={8} display={['none', 'none', 'none', 'block']} title="Töösse võetud" />

        <HeaderCell width={8} display={['none', 'none', 'none', 'block']} title="Lõpetatud" />

        <HeaderCell width={9} display="block" title="Seis" />
      </Box>
    </Box>
  );
}

export function constructOrderRow(order: Data, locations: any, statuses: any): OrderRow {
  return {
    id: order.i,
    name: locations[order.j.locationId],
    referenceNr: order.j.referenceNr,
    callTime: order.j.callTime,
    inserted: order.j.inserted,
    startedWorking: order.j.startedWorking,
    completed: order.j.completed,
    status: statuses[order.j.status]
  };
}
