import { Data } from '../api/api';

export function setInitialEntity(id: string | undefined, j: any, type?: string): Data {
  return {
    i: id ? id : '',
    t: type ? type : '',
    j: j,
    dt: '',
    v: 1
  };
}
