import React, { useEffect, useState } from 'react';
import { Data, getEntitiesByReference, getEntityById } from '../api/api';
import { setInitialEntity } from '../utils/InitialObjectMapper';
import { checkAPIError } from '../services/ErrorService';
import ContentContainer from '../components/layout/ContentContainer';
import OrderChangeForm from '../components/orders/OrderChangeForm';
import { Box, Label, Text } from '@primer/react';
import OrderPreview from '../components/orders/OrderPreview';
import OrderFullView from '../components/orders/OrderFullView';
import OrderActivityLog from '../components/orders/OrderActivityLog';
import { useParams } from 'react-router';
import { H2 } from '../components/utilities/typography';
import PageHead from '../components/layout/PageHead';

function OrdersContainer() {
  const { id } = useParams();

  const [order, setOrder] = useState(setInitialEntity(id, {}));
  const [location, setLocation] = useState(setInitialEntity('', {}));
  const [reload, setReload] = useState(false);
  const [files, setFiles] = useState<Data[]>([]);
  const [apiError, setApiError] = useState('');
  const [customer, setCustomer] = useState('');

  useEffect(() => {
    loadOrderData(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleErrors(error: any) {
    const err = checkAPIError(error);
    setApiError(err);
  }

  function clearApiError() {
    setApiError('');
  }

  async function loadOrderData(initial?: boolean) {
    return Promise.resolve(getEntityById('orders', order.i))
      .then(result => {
        setOrder(result.data);
        setReload(initial ? reload : !reload);
        return loadLocationData(result.data.j.locationId);
      })
      .then(() => {
        return getEntitiesByReference('attachments', 'parentId', order.i);
      })
      .then(result => {
        setFiles(result.data);
      })
      .catch(e => {
        handleErrors(e);
      });
  }

  function loadLocationData(locationId: string) {
    if (!locationId) {
      return;
    }

    return getEntityById('locations', locationId)
      .then(result => {
        setLocation(result.data);
        return getEntityById('customers', result.data.j.customerId);
      })
      .then(result => {
        setCustomer(result.data.j.name);
      })
      .catch(e => {
        handleErrors(e);
      });
  }

  function showOrderPreview() {
    return order.j.status && order.j.status === 'pending';
  }

  function showOrderChangeForm() {
    return order.j.status && order.j.status === 'inProgress';
  }

  function showFullOrderView() {
    return order.j.status && order.j.status !== 'pending' && order.j.status !== 'inProgress';
  }

  function getTitle() {
    return (
      <>
        Tellimus <Text fontSize={'smaller'}>{order.i.substring(0, 8)}</Text>
        {order.j.status === 'canceled' && (
          <Label sx={{ m: 1, bg: 'red.4' }} className="v-align-middle">
            Tühistatud
          </Label>
        )}
      </>
    );
  }

  return (
    <Box display="flex">
      <Box display="flex" flexDirection="column" width={[1, 1, 1, 0.8]}>
        <ContentContainer>
          <PageHead title={getTitle()} errorMsg={apiError}></PageHead>
          {showOrderPreview() && (
            <OrderPreview
              order={order}
              location={location}
              customer={customer}
              files={files}
              reloadOrder={loadOrderData}
              errorHandler={handleErrors}
            />
          )}

          {showOrderChangeForm() && (
            <OrderChangeForm
              order={order}
              location={location}
              customer={customer}
              files={files}
              reloadOrder={loadOrderData}
              reloadLocation={loadLocationData}
              errorHandler={handleErrors}
              clearMessages={clearApiError}
              setError={(e: string) => setApiError(e)}
            />
          )}
          {showFullOrderView() && (
            <OrderFullView
              order={order}
              location={location}
              customer={customer}
              files={files}
              reloadOrder={loadOrderData}
              errorHandler={handleErrors}
            />
          )}
        </ContentContainer>
      </Box>

      <Box
        flexDirection="column"
        display={['none', 'none', 'none', 'none', 'flex']}
        width={0.3}
        pr={4}
        sx={{ bg: 'canvas.default', pt: 3 }}>
        <H2>Tegevuste ajalugu</H2>
        <OrderActivityLog orderId={order.i} update={reload} />
      </Box>
    </Box>
  );
}

export default OrdersContainer;
