import React, { useState } from 'react';
import { Box, Button, Text } from '@primer/react';
import { Dialog } from '@primer/react/experimental';
import { changeEntity, Data, sendEmail } from '../../api/api';
import OrderDataComponent from './OrderDataComponent';
import FormFooter from '../FormFooter';
import { useNavigate } from 'react-router-dom';
import { getCurrentUserRole } from '../../services/CurrentUserService';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import { ArrowLeftIcon } from '@primer/octicons-react';

interface OrderProps {
  order: Data;
  location: Data;
  customer: string;
  files: Data[];
  reloadOrder: any;
  errorHandler: any;
}

function OrderPreview(props: OrderProps) {
  useDocumentTitle(['Tellimused', 'Tellimus']);

  const [showAcceptConfirm, setShowAcceptConfirm] = useState(false);
  const [showRejectConfirm, setShowRejectConfirm] = useState(false);
  const navigate = useNavigate();

  function isNewEmailOrder() {
    return props.order.j.fromEmail && props.order.j.accepted === undefined;
  }

  function isRejectedOrder() {
    return props.order.j.fromEmail && props.order.j.accepted === false;
  }

  function updateOrderData(data: any): Promise<any> {
    return changeEntity('orders', props.order, data)
      .then(() => {
        return props.reloadOrder();
      })
      .catch(props.errorHandler);
  }

  function acceptOrder(withEmail: boolean) {
    setShowAcceptConfirm(false);
    if (withEmail) {
      const emailData = {
        referenceNr: props.order.j.referenceNr,
        emailRef: props.order.j.emailRef
      };

      return sendEmail('emails/accept', emailData)
        .then(() => {
          return updateOrderData({ accepted: true });
        })
        .catch(props.errorHandler);
    }

    return updateOrderData({ accepted: true });
  }

  function rejectOrder(withEmail: boolean) {
    setShowRejectConfirm(false);
    if (withEmail) {
      const emailData = {
        referenceNr: props.order.j.referenceNr,
        emailRef: props.order.j.emailRef
      };

      return sendEmail('emails/reject', emailData)
        .then(() => {
          return updateOrderData({ accepted: false });
        })
        .catch(props.errorHandler);
    }
    return updateOrderData({ accepted: false });
  }

  function startWorking() {
    const data = {
      status: 'inProgress',
      startedWorking: new Date().toISOString()
    };

    return updateOrderData(data);
  }

  function cancelOrder(): Promise<any> {
    const data = {
      status: 'canceled',
      completed: new Date().toISOString()
    };

    return updateOrderData(data);
  }

  return (
    <>
      <Box p={2}>
        <OrderDataComponent
          order={props.order}
          location={props.location}
          customer={props.customer}
          files={props.files}
          errorHandler={props.errorHandler}
        />

        <FormFooter>
          <Button onClick={() => navigate('/orders')} leadingVisual={ArrowLeftIcon}>
            Tagasi
          </Button>

          {getCurrentUserRole() === 'admin' && (
            <Button onClick={() => navigate(`/orders/${props.order.i}/edit`)}>Muuda</Button>
          )}

          {(isNewEmailOrder() || (!isNewEmailOrder() && isRejectedOrder())) && (
            <Button variant="primary" onClick={() => setShowAcceptConfirm(true)}>
              Aktsepteeri
            </Button>
          )}

          {isNewEmailOrder() && (
            <Button variant="danger" onClick={() => setShowRejectConfirm(true)}>
              Lükka tagasi
            </Button>
          )}

          {!isNewEmailOrder() && !isRejectedOrder() && (
            <Button variant="primary" onClick={startWorking}>
              Võta töösse
            </Button>
          )}

          {!isNewEmailOrder() && getCurrentUserRole() === 'admin' && (
            <Button variant="danger" onClick={() => cancelOrder()}>
              Tühista
            </Button>
          )}
        </FormFooter>
      </Box>

      {showAcceptConfirm && (
        <EmailConfirmationDialog
          content="Kas soovid saata automaatse e-kirja intsidendi aksepteerimiseks?"
          onClose={() => setShowAcceptConfirm(false)}
          onChoose={(withEmail) => acceptOrder(withEmail)}></EmailConfirmationDialog>
      )}

      {showRejectConfirm && (
        <EmailConfirmationDialog
          content="Kas soovid saata automaatse e-kirja intsidendi tagasi lükkamiseks?"
          onClose={() => setShowRejectConfirm(false)}
          onChoose={(withEmail) => rejectOrder(withEmail)}></EmailConfirmationDialog>
      )}
    </>
  );
}

function EmailConfirmationDialog(props: {
  content: string;
  onClose: () => void;
  onChoose: (withEmail: boolean) => any;
}) {
  return (
    <Dialog
      title="E-kirja kinnitus"
      onClose={props.onClose}
      aria-label="E-kirja saatmise kinnitus hüpikaken"
      footerButtons={[
        { buttonType: 'default', content: 'Katkesta', onClick: props.onClose },
        { buttonType: 'default', content: 'Ära saada', onClick: () => props.onChoose(false) },
        { buttonType: 'primary', content: 'Saada', onClick: () => props.onChoose(true) }
      ]}>
      <Box sx={{ py: 2 }}>
        <Text>{props.content}</Text>
      </Box>
    </Dialog>
  );
}

export default OrderPreview;
