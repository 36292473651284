import restTypedAxios from 'restyped-axios';
import { CoreAPI } from 'kiisu-api-types/core';
import { MarkupNew, SpareGroupNew, SpareNew, SpareStored } from 'kiisu-api-types/core.spares';
import { getBearerToken } from './api';

const coreAPI = restTypedAxios.create<CoreAPI>({
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
});

export function getMarkups() {
  return coreAPI.get<'/core/v2/markups'>('/core/v2/markups', {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function postMarkup(data: MarkupNew) {
  return coreAPI.post<'/core/v2/markups'>('/core/v2/markups', data, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function getSpareGroups() {
  return coreAPI.get<'/core/v2/spare-groups'>('/core/v2/spare-groups', {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function postSpareGroup(data: SpareGroupNew) {
  return coreAPI.post<'/core/v2/spare-groups'>('/core/v2/spare-groups', data, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function getSpares(groupId?: string) {
  return coreAPI.get<'/core/v2/spares'>('/core/v2/spares', {
    params: {
      groupId: groupId
    },
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function postSpare(data: SpareNew) {
  return coreAPI.post<'/core/v2/spares'>('/core/v2/spares', data, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function getSpare(id: string) {
  return coreAPI.get<'/core/v2/spares/:id'>(`/core/v2/spares/${id}`, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function patchSpare(spare: SpareStored) {
  return coreAPI.patch<'/core/v2/spares/:id'>(`/core/v2/spares/${spare.metadata.id}`, spare, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}
