import React, { useCallback, useEffect, useState } from 'react';
import { Box, Text } from '@primer/react';
import { retrieveFile } from '../../api/api';
import PDFIcon from '../../assets/PDF_icon.svg';
import XLSXIcon from '../../assets/xlsx_icon.svg';
import VideoIcon from '../../assets/video-file.png';
import FileViewer from './FileViewer';
import ConfirmDialog from '../ConfirmDialog';
import { GalleryImage, MemoizedGallery } from './Gallery';

export interface Attachment {
  parentId: string;
  filename: string;
  hash: string;
  mimeType: string;
}

export function isPDFFile(type: string) {
  const regex = /pdf/;
  return type.match(regex);
}

export function isExcelFile(type: string) {
  const regex = /(spreadsheet)|(excel)/;
  return type.match(regex);
}

export function isVideoFile(type: string) {
  const regex = /video*/;
  return type.match(regex);
}

function AttachmentGallery(props: {
  files: Attachment[];
  errorHandler: any;
  handleFileRemove?: any;
  newWindow?: boolean;
}) {
  const [images, setImages] = useState<GalleryImage[]>([]);
  const [currentImage, setCurrentImage] = useState<GalleryImage>();
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [showConfirm, toggleConfirm] = useState(false);

  function getThumbnail(type: string, dataURL: string) {
    if (isPDFFile(type)) {
      return PDFIcon;
    }

    if (isExcelFile(type)) {
      return XLSXIcon;
    }

    if (isVideoFile(type)) {
      return VideoIcon;
    }

    return dataURL;
  }

  function convertFileToDataUrl(content: Blob, filename: string, hash: string) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = async result => {
        const dataURL = result.target ? String(result.target.result) : '';
        resolve({
          blob: content,
          filename: filename,
          mimeType: content.type,
          hash: hash,
          dataURL: dataURL,
          thumbnail: getThumbnail(content.type, dataURL)
        });
      };

      reader.readAsDataURL(content);
    });
  }

  useEffect(() => {
    let loaded: GalleryImage[] = [...images];

    async function getFile(hash: string, filename: string) {
      await retrieveFile('attachments', hash)
        .then(result => {
          return convertFileToDataUrl(result.data, filename, hash);
        })
        .then(image => {
          loaded.push(image as GalleryImage);
        })
        .catch(props.errorHandler);
    }

    async function processFiles() {
      if (props.files.length > 0) {
        for (const file of props.files) {
          if (!loaded.find(i => i.hash === file.hash)) {
            await getFile(file.hash, file.filename);
          }
        }
      }

      if (props.files.length < loaded.length) {
        loaded = loaded.filter(i => !!props.files.find(f => f.hash === i.hash));
      }

      setImages(loaded);
    }

    processFiles().then(() => console.log('All files processed.'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.files.length]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onClickHandler = useCallback(
    (file: GalleryImage) => (props.newWindow ? openNewWindow(file) : openDialog(file)),
    [props.newWindow]
  );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDeleteHandler = useCallback((file: GalleryImage) => deleteFile(file, false), []);

  const openNewWindow = (file: GalleryImage) => {
    const fileURL = URL.createObjectURL(file.blob);
    window.open(fileURL, '_self');
  };

  const openDialog = (file: GalleryImage) => {
    setCurrentImage(file);
    setViewerIsOpen(true);
    window.scrollTo(0, 0);
  };

  const closeDialog = () => {
    setCurrentImage(undefined);
    setViewerIsOpen(false);
  };

  function deleteFile(file: GalleryImage, confirmed: boolean) {
    setCurrentImage(file);

    if (confirmed) {
      props.handleFileRemove(file.hash);
      toggleConfirm(false);
      return;
    }

    toggleConfirm(true);
  }

  return (
    <>
      {images.length > 0 && viewerIsOpen && (
        <FileViewer image={currentImage} closeDialog={closeDialog} handleFileRemove={props.handleFileRemove} />
      )}
      {images.length > 0 && (
        <MemoizedGallery
          images={images}
          showDeleteIcon={props.newWindow}
          onClick={onClickHandler}
          onDelete={onDeleteHandler}
        />
      )}
      {currentImage && (
        <ConfirmDialog
          showConfirm={showConfirm}
          closeModal={() => toggleConfirm(false)}
          onClick={() => deleteFile(currentImage, true)}>
          <Box p={2}>
            <Text as="p">Kas oled kindel, et soovid faili kustutada?</Text>
          </Box>
        </ConfirmDialog>
      )}
    </>
  );
}

export default AttachmentGallery;
