import React from 'react';
import ListTable from '../components/ListTable';
import ContentContainer from '../components/layout/ContentContainer';
import { useListAPI } from '../hooks/ApiHooks';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import PageHead from '../components/layout/PageHead';

function Users() {
  useDocumentTitle(['Kasutajad']);

  const [users, userError] = useListAPI('users');
  const [userRoles, rolesError] = useListAPI('user-roles');

  function getRoleName(roleId: string) {
    const role = userRoles.find(data => data.j.role === roleId);
    return role ? role.j.text : '';
  }

  const headers = { name: 'Nimi', username: 'Kasutajanimi', phone: 'Telefon', role: 'Roll' };
  const rows = users.map((row: any) => ({
    id: row.i,
    name: row.j.firstName + ' ' + row.j.lastName,
    username: row.j.username,
    phone: row.j.phone,
    role: getRoleName(row.j.role)
  }));

  return (
    <ContentContainer xlarge>
      <PageHead title="Kasutajad" add="/users/add" errorMsg={userError || rolesError}></PageHead>
      <ListTable
        rows={rows}
        headers={headers}
        edit={{ key: 'name', url: '/users/:id' }}
        hideOnSmall={['username', 'phone']}
      />
    </ContentContainer>
  );
}

export default Users;
