import React from 'react';
import ListTable from '../components/ListTable';
import ContentContainer from '../components/layout/ContentContainer';
import { useListAPI } from '../hooks/ApiHooks';
import { getCurrentUserRole } from '../services/CurrentUserService';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import PageHead from '../components/layout/PageHead';

function Locations() {
  useDocumentTitle(['Asukohad']);

  const [locations, locError] = useListAPI('locations');
  const [customers, custError] = useListAPI('customers');

  function getCustomerName(customerId: string) {
    const customer = customers.find(data => data.i === customerId);
    return customer ? customer.j.name : '';
  }

  const headers = { name: 'Nimetus', customer: 'Klient', address: 'Aadress', phone: 'Telefon' };

  const rows = locations.map(loc => ({
    id: loc.i,
    name: loc.j.name,
    customer: getCustomerName(loc.j.customerId),
    address: loc.j.address,
    phone: loc.j.phone
  }));

  function hasError() {
    if (locError) {
      return locError;
    }
    if (custError) {
      return custError;
    }
    return '';
  }

  return (
    <ContentContainer xlarge>
      <PageHead
        title="Asukohad"
        add={getCurrentUserRole() === 'admin' ? '/locations/add' : undefined}
        errorMsg={hasError()}></PageHead>
      <ListTable
        rows={rows}
        headers={headers}
        edit={{ key: 'name', url: '/locations/:id' }}
        hideOnSmall={['phone', 'customer']}
      />
    </ContentContainer>
  );
}

export default Locations;
