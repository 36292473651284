import React from 'react';
import { Box, Spinner, Text } from '@primer/react';

function CustomLoader() {
  return (
    <Box display="flex" alignItems="center">
      <Spinner size="small" sx={{ mr: 2 }} />
      <Text>Laen andmeid...</Text>
    </Box>
  );
}

export default CustomLoader;
