import React, { useEffect, useState } from 'react';
import { DateRange } from '../DateRangePicker';
import { Box } from '@primer/react';
import CustomLoader from '../CustomLoader';
import { getInvoiceReport } from '../../api/api.reports';
import { checkAPIError } from '../../services/ErrorService';
import { InvoiceRow } from 'kiisu-api-types/core.reports';
import { Blankslate, BlankslateHeading, BlankslateIcon } from '../blankslate';
import ReportBox from './ReportBox';
import { RowsIcon } from '@primer/octicons-react';
import IntroductionBlankslate from '../IntroductionBlankslate';
import { Banner } from '@primer/react/experimental';

function InvoiceReportv2(props: { dateRange: DateRange; customerId: string | null }) {
  const [apiError, setApiError] = useState(undefined as string | undefined);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState<InvoiceRow[]>([]);
  const [groupedRows, setGroupedRows] = useState(Object.create(null));
  const [emptyResult, setEmptyResult] = useState(false);

  useEffect(() => {
    setEmptyResult(false);
    setApiError('');

    // If date range is not set, do not make request!
    if (!props.dateRange[0] || !props.dateRange[1]) {
      return;
    }

    const params = {
      customerId: props.customerId ? props.customerId : undefined,
      startDate: props.dateRange[0].toISOString(),
      endDate: props.dateRange[1].toISOString()
    };

    setLoading(true);

    getInvoiceReport(params)
      .then(res => {
        setRows(res.data.items);

        if (res.data.items.length === 0) {
          setEmptyResult(true);
        } else {
          setGroupedRows(groupAndSortRows(res.data.items));
        }

        setLoading(false);
      })
      .catch(error => setApiError(checkAPIError(error)));
  }, [props.dateRange, props.customerId]);

  function groupAndSortRows(input: InvoiceRow[]) {
    const grouped = input.reduce((prev, current) => {
      prev[current.customer.name] = prev[current.customer.name] || [];
      prev[current.customer.name].push(current);
      return prev;
    }, Object.create(null));

    const sorted = Object.keys(grouped)
      .sort()
      .reduce(
        (acc, key) => ({
          ...acc,
          [key]: grouped[key]
        }),
        {}
      );

    return sorted;
  }

  return (
    <Box sx={{ display: 'grid', gap: 3, py: 3 }}>
      {apiError && (
        <Box sx={{ width: '100%', mb: 4 }}>
          <Banner title={apiError} variant="critical"></Banner>
        </Box>
      )}

      {loading && <CustomLoader />}

      {!loading &&
        rows.length > 0 &&
        Object.keys(groupedRows).map(customer => (
          <ReportBox key={customer} reportType="invoice" rows={groupedRows[customer]} />
        ))}

      {!loading && emptyResult && (
        <Blankslate spacious={true}>
          <BlankslateIcon icon={RowsIcon} size={24} />
          <BlankslateHeading>Päring ei tagastanud ühtegi rida.</BlankslateHeading>
        </Blankslate>
      )}

      {!loading &&
        !emptyResult &&
        rows.length === 0 && ( // Initial loading of the report page without search parameters
          <IntroductionBlankslate
            heading="Raportit ei ole veel koostatud."
            description="Raporti koostamiseks vali kuupäevade vahemik."
          />
        )}
    </Box>
  );
}

export default InvoiceReportv2;
