import React, { useEffect, useState } from 'react';
import { addEntity, changeEntity, Data, deleteEntity } from '../api/api';
import { checkAPIError, handleMultipleErrors } from '../services/ErrorService';
import { Box, Button, FormControl, TextInput } from '@primer/react';
import { useEntitiesByRefAPI, useEntityByIdAPI, useListAPI } from '../hooks/ApiHooks';
import FileUploader, { SavedFile } from '../components/uploads/FileUploader';
import AttachmentGallery, { Attachment } from '../components/uploads/AttachmentGallery';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';
import SingleDateTimePicker from '../components/SingleDateTimePicker';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { Controller, useForm } from 'react-hook-form';
import CustomSelect from '../components/CustomSelect';
import { ArrowLeftIcon } from '@primer/octicons-react';
import FormFooter from '../components/FormFooter';
import ContentContainer from '../components/layout/ContentContainer';
import PageHead from '../components/layout/PageHead';

interface FormValues {
  i: string;
  locationId: string;
  referenceNr: string;
  phone: string;
  orderer: string;
  callTime: string;
  description: string;
  notes: string;
  status: string;
  inserted: string;
  address: string;
}

interface OrderInitialFormProps {
  preloadedValues?: FormValues;
  errorHandler: any;
  setError: any;
  images: Attachment[];
  setImages: any;
  locations: Data[];
  files: Data[];
  setFiles: any;
}

function OrderInitialForm(props: OrderInitialFormProps) {
  useDocumentTitle(['Tellimused', 'Tellimus']);

  const navigate = useNavigate();

  async function resolvePromise(promise: Promise<any>) {
    return Promise.resolve(promise)
      .then(result => {
        const additions = props.images.map((i: any) => {
          const a = i;
          a.parentId = result.data.i;
          return addEntity('attachments', a);
        });

        return Promise.all(additions);
      })
      .then(() => {
        navigate('/orders');
      })
      .catch(props.errorHandler);
  }

  function handleAddOrChange(form: FormValues) {
    if (props.preloadedValues?.i) {
      return resolvePromise(changeEntity('orders', form, form));
    }

    form.status = 'pending';
    form.inserted = new Date().toISOString();

    return resolvePromise(addEntity('orders', form));
  }

  async function handleSavedFiles(files: SavedFile[]) {
    const data = files.map(f => {
      return {
        parentId: '',
        filename: f.originalname,
        hash: f.filename,
        mimeType: f.mimetype
      };
    });
    props.setImages([...props.images, ...data]);
  }

  async function handleFileRemove(hash: string) {
    return deleteEntity('attachments', hash, 1)
      .then(() => {
        const newImages = props.images.filter((i: any) => i.hash !== hash);
        props.setImages(newImages);
        props.setFiles(props.files.filter((f: any) => f.j.hash !== hash));
      })
      .catch(props.errorHandler);
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    getFieldState,
    watch
  } = useForm<FormValues>({ defaultValues: props.preloadedValues });

  const watchedLocation = watch('locationId');

  useEffect(() => {
    const selected = props.locations.find((location: any) => location.i === watchedLocation);

    if (!getFieldState('phone').isTouched && selected?.j['phone']) {
      setValue('phone', selected.j['phone']);
    }

    if (selected?.j['address']) {
      setValue('address', selected.j['address']);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedLocation, props.locations]);

  function clearErrors() {
    props.setError('');
  }

  const locationOptions = props.locations.map((l: any) => ({
    label: l.j.name,
    value: l.i
  }));

  const galleryFiles = [...props.images, ...props.files.map((f: any) => f.j)];

  function handleDateChange(date: Date | null) {
    let value;

    if (date) {
      value = date.toISOString();
    } else {
      value = '';
    }

    setValue('callTime', value);
  }

  return (
    <form onSubmit={handleSubmit(handleAddOrChange)} onChange={clearErrors}>
      <Box sx={{ display: 'grid', gap: 4, mb: 2 }}>
        <FormControl>
          <FormControl.Label>Kliendi viide</FormControl.Label>
          <TextInput block {...register('referenceNr')} />
        </FormControl>

        <FormControl>
          <FormControl.Label htmlFor="locationId">Objekt</FormControl.Label>
          <Controller
            name="locationId"
            control={control}
            rules={{ required: { value: true, message: 'Määra asukoha objekt' } }}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                inputId="locationId"
                placeholder="Vali objekt..."
                options={locationOptions}
                value={locationOptions.filter((location: any) => value === location.value)}
                onChange={(event: any) => onChange(event.value)}
                aria-errormessage={errors.locationId?.message}
                aria-invalid={!!errors.locationId}
              />
            )}
          />
          {errors.locationId && (
            <FormControl.Validation variant="error">{errors.locationId.message}</FormControl.Validation>
          )}
        </FormControl>

        <FormControl disabled>
          <FormControl.Label>Aadress</FormControl.Label>
          <TextInput block {...register('address')} />
        </FormControl>

        <FormControl>
          <FormControl.Label>Telefon</FormControl.Label>
          <TextInput block {...register('phone')} />
        </FormControl>

        <FormControl>
          <FormControl.Label>Tellija</FormControl.Label>
          <TextInput
            block
            {...register('orderer', {
              required: {
                value: true,
                message: 'Sisesta tellija andmed'
              }
            })}
          />
          {errors.orderer && <FormControl.Validation variant="error">{errors.orderer.message}</FormControl.Validation>}
        </FormControl>

        <FormControl>
          <FormControl.Label htmlFor="callTime">Väljakutse aeg</FormControl.Label>
          <Controller
            name="callTime"
            control={control}
            rules={{
              required: { value: true, message: 'Vali väljakutse aeg' }
            }}
            render={({ field }) => (
              <SingleDateTimePicker
                name="callTime"
                id="callTime"
                value={field.value}
                onChange={d => handleDateChange(d)}
              />
            )}
          />
          {errors.callTime && (
            <FormControl.Validation variant="error">{errors.callTime.message}</FormControl.Validation>
          )}
        </FormControl>

        <FormControl>
          <FormControl.Label>Probleem/Tellimus</FormControl.Label>
          <TextInput
            block
            {...register('description', {
              required: {
                value: true,
                message: 'Kirjelda tellija probleemi'
              }
            })}
          />
          {errors.description && (
            <FormControl.Validation variant="error">{errors.description.message}</FormControl.Validation>
          )}
        </FormControl>

        <FormControl>
          <FormControl.Label>Märkused</FormControl.Label>
          <TextInput block {...register('notes')} />
        </FormControl>

        {galleryFiles.length > 0 && (
          <AttachmentGallery
            files={galleryFiles}
            errorHandler={props.errorHandler}
            handleFileRemove={handleFileRemove}
            newWindow={true}
          />
        )}

        <Box display="flex" justifyContent="space-between" mt="1">
          <FileUploader
            setUploadError={props.setError}
            apiErrorHandler={props.errorHandler}
            handleSavedFiles={handleSavedFiles}
            clearErrors={clearErrors}
          />

          <FormFooter>
            <Button onClick={() => navigate(-1)} leadingVisual={ArrowLeftIcon}>
              Tagasi
            </Button>

            <Button variant="primary" type="submit">
              {props.preloadedValues?.i ? 'Salvesta' : 'Lisa'}
            </Button>
          </FormFooter>
        </Box>
      </Box>
    </form>
  );
}

function OrderInitialPage() {
  const { id } = useParams();

  const [error, setError] = useState('');
  const [entity, ordersError] = useEntityByIdAPI(id, 'orders');
  const [addedImages, setAddedImages] = useState(new Array<Attachment>());
  const [locations, locationsError] = useListAPI('locations');
  const [files, filesError, setFiles] = useEntitiesByRefAPI('attachments', 'parentId', id);

  handleMultipleErrors(error, ordersError, setError);
  handleMultipleErrors(error, locationsError, setError);
  handleMultipleErrors(error, filesError, setError);

  function errorHandler(error: any): void {
    setError(checkAPIError(error));
  }

  function dataToFormData(entity?: Data) {
    if (entity) {
      return {
        i: entity.i,
        v: entity.v,
        t: entity.t,
        locationId: entity.j.locationId,
        referenceNr: entity.j.referenceNr,
        phone: entity.j.phone,
        orderer: entity.j.orderer,
        callTime: entity.j.callTime,
        description: entity.j.description,
        notes: entity.j.notes,
        status: entity.j.status,
        inserted: entity.j.inserted,
        address: entity.j.address
      };
    }
  }

  return (
    <ContentContainer>
      <PageHead title={entity.i ? 'Muuda tellimuse andmeid' : 'Lisa uus tellimus'} errorMsg={error}></PageHead>
      {(!entity.i || entity.j.locationId) && (
        <OrderInitialForm
          preloadedValues={dataToFormData(entity)}
          errorHandler={errorHandler}
          setError={setError}
          images={addedImages}
          setImages={setAddedImages}
          locations={locations}
          files={files}
          setFiles={setFiles}
        />
      )}
    </ContentContainer>
  );
}

export default OrderInitialPage;
