import { useLocation, useParams } from 'react-router';
import React, { useEffect, useState } from 'react';
import { Box, Button, SubNav } from '@primer/react';
import { Data, getEntities } from '../api/api';
import { checkAPIError } from '../services/ErrorService';
import DateRangePicker, { DateRange } from '../components/DateRangePicker';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { endOfDay, startOfDay } from 'date-fns';
import CustomSelect from '../components/CustomSelect';
import { FilterBox, FilterWrapper } from '../components/filter';
import InvoiceReportv2 from '../components/reports/InvoiceReportv2';
import SalaryReportv2 from '../components/reports/SalaryReportv2';
import { getReportCSVFile } from '../api/api.reports';
import FileSaver from 'file-saver';
import { DocumentTitleTranslation, useDocumentTitle } from '../hooks/useDocumentTitle';
import { Banner } from '@primer/react/experimental';
import ContentContainer from '../components/layout/ContentContainer';
import PageHead from '../components/layout/PageHead';

function Reportsv2() {
  const location = useLocation();
  const navigate = useNavigate();
  const { type } = useParams();

  const [searchParams] = useSearchParams();
  const [apiError, setApiError] = useState(undefined as string | undefined);
  const [summary, setSummary] = useState(true);
  const [reportType, setReportType] = useState(type);
  const [customers, setCustomers] = useState<Data[]>([]);
  const [dateRange, setDateRange] = useState(getDateRange(searchParams.get('startDate'), searchParams.get('endDate')));
  const [customerId, setCustomerId] = useState(searchParams.get('customerId'));

  const titleComponent = ['Raportid', reportType ? getPageTitle() : ''];
  const titleTranslations: DocumentTitleTranslation = {
    summary: 'Kokkuvõte',
    detailed: 'Detailne'
  };

  useDocumentTitle(titleComponent, titleTranslations);

  useEffect(() => {
    getEntities('customers')
      .then(res => setCustomers(res.data))
      .catch(e => setApiError(checkAPIError(e)));
  }, []);

  useEffect(() => {
    setReportType(type);
  }, [type]);

  useEffect(() => {
    const hash = location.hash ? location.hash.slice(1) : undefined;
    setSummary(hash === 'summary');
  }, [location.hash]);

  useEffect(() => {
    const range: DateRange = getDateRange(searchParams.get('startDate'), searchParams.get('endDate'));
    setDateRange(range);
    setCustomerId(searchParams.get('customerId'));
  }, [searchParams]);

  function getDateRange(start: string | null, end: string | null): DateRange {
    const paramsStart = start ? new Date(start) : undefined;
    const paramsEnd = end ? new Date(end) : undefined;
    return [paramsStart, paramsEnd];
  }

  function getPageTitle(): string {
    switch (reportType) {
      case 'invoice':
        return 'Arveread';
      case 'salary':
        return 'Töötunnid';
      default:
        return 'Raportid';
    }
  }

  useEffect(() => {
    setApiError('');

    const start = dateRange[0] ? startOfDay(dateRange[0]).toISOString() : undefined;
    const end = dateRange[1] ? endOfDay(dateRange[1]).toISOString() : undefined;
    const customer = customerId ? customerId : undefined;

    const urlParams = new URLSearchParams(location.search);
    addOrRemoveParam('startDate', start, urlParams);
    addOrRemoveParam('endDate', end, urlParams);
    addOrRemoveParam('customerId', customer, urlParams);

    navigate({
      pathname: location.pathname,
      hash: location.hash,
      search: urlParams.toString()
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, customerId]);

  function addOrRemoveParam(name: string, value: string | undefined, urlParams: URLSearchParams) {
    if (value) {
      urlParams.set(name, value);
    } else {
      urlParams.delete(name);
    }
  }

  function saveCSV() {
    if (!dateRange[0] || !dateRange[1] || (reportType !== 'invoice' && reportType !== 'salary')) {
      return;
    }

    const params = {
      customerId: customerId ? customerId : undefined,
      startDate: dateRange[0].toISOString(),
      endDate: dateRange[1].toISOString()
    };

    return getReportCSVFile(reportType, params)
      .then(res => {
        const disposition: string = res.headers['content-disposition'];
        const filename = disposition.split('filename=')[1];
        FileSaver.saveAs(res.data, filename);
      })
      .catch((error: any) => setApiError(checkAPIError(error)));
  }

  const customerOptions = customers.map(c => ({
    label: c.j.name,
    value: c.i
  }));

  return (
    <ContentContainer large>
      <PageHead title={getPageTitle()}></PageHead>

      <Box display="grid" gridGap={3} pt={3}>
        {apiError && (
          <Box sx={{ width: '100%' }}>
            <Banner title={apiError} variant="critical"></Banner>
          </Box>
        )}

        <Box display="flex" flexWrap="wrap" alignItems="center" justifyContent="space-between" gridGap={3}>
          <FilterWrapper>
            <FilterBox>
              <DateRangePicker
                name="callTime"
                value={dateRange ? dateRange : [undefined, undefined]}
                onChange={(value: DateRange) => setDateRange(value)}
              />
            </FilterBox>

            {reportType === 'invoice' && (
              <FilterBox>
                <CustomSelect
                  name="customerId"
                  placeholder="Vali klient..."
                  value={customerOptions.filter(c => customerId === c.value)}
                  onChange={(event: any) => setCustomerId(event?.value)}
                  options={customerOptions}
                  isClearable={true}
                />
              </FilterBox>
            )}
            {reportType === 'salary' && (
              <SubNav>
                <SubNav.Links>
                  <SubNav.Link href="#summary" selected={summary} onClick={() => setSummary(true)}>
                    Kokkuvõte
                  </SubNav.Link>
                  <SubNav.Link href="#detailed" selected={!summary} onClick={() => setSummary(false)}>
                    Detailne
                  </SubNav.Link>
                </SubNav.Links>
              </SubNav>
            )}
          </FilterWrapper>

          {dateRange[0] && dateRange[1] && !summary && <Button onClick={saveCSV}>Salvesta CSV</Button>}
        </Box>
      </Box>

      {reportType === 'invoice' && <InvoiceReportv2 dateRange={dateRange} customerId={customerId} />}

      {reportType === 'salary' && <SalaryReportv2 dateRange={dateRange} userId={customerId} summary={summary} />}
    </ContentContainer>
  );
}

export default Reportsv2;
