import React from 'react';
import { changeEntity, Data } from '../../api/api';
import { Text } from '@primer/react';
import ConfirmDialog from '../ConfirmDialog';

interface DialogProps {
  showConfirm: boolean;
  toggleConfirm: any;
  order: Data;
  reloadOrder: any;
  errorHandler: any;
}

function OrderReopenDialog(props: DialogProps) {
  function reopen() {
    const data = {
      status: 'inProgress',
      clientName: '',
      clientSignature: '',
      completed: '',
      startedWorking: new Date().toISOString()
    };

    return changeEntity('orders', props.order, data)
      .then(() => {
        return props.reloadOrder();
      })
      .catch(props.errorHandler);
  }

  return (
    <ConfirmDialog
      showConfirm={props.showConfirm}
      closeModal={() => props.toggleConfirm(false)}
      onClick={() => reopen()}>
      <Text as="p">Soovid tellimuse uuesti avada.</Text>
      <Text as="p" fontWeight="bold">
        Tellimuse uuesti avamisel kliendi kinnitus tühistatakse!
      </Text>
      <Text as="p">Kas oled kindel, et soovid tellimuse uuesti avada?</Text>
    </ConfirmDialog>
  );
}

export default OrderReopenDialog;
