import React, { useEffect, useRef, useState } from 'react';
import { addEntity, changeEntity, Data, deleteEntity, sendEmail } from '../../api/api';
import './ReactSignatureCanvas.css';
import {
  ActionList,
  ActionMenu,
  Box,
  Button,
  FormControl,
  Octicon,
  Text,
  Textarea,
  TextInput,
  useTheme
} from '@primer/react';
import ConfirmDialog from '../ConfirmDialog';
import { ArrowLeftIcon, XIcon } from '@primer/octicons-react';
import { getCurrentUserRole } from '../../services/CurrentUserService';
import AttachmentGallery from '../uploads/AttachmentGallery';
import FileUploader, { SavedFile } from '../uploads/FileUploader';
import { useListAPI } from '../../hooks/ApiHooks';
import SingleDateTimePicker from '../SingleDateTimePicker';
import WorkProgressList from '../workData/WorkProgressList';
import { useDocumentTitle } from '../../hooks/useDocumentTitle';
import ReactSignatureCanvas from 'react-signature-canvas';
import { Controller, useForm } from 'react-hook-form';
import CustomSelect from '../CustomSelect';
import { H2, H3 } from '../utilities/typography';
import { useNavigate } from 'react-router-dom';
import WorkDataDialog from '../workData/WorkDataDialog';
import FormFooter from '../FormFooter';

interface OrderChangeFormProps {
  order: Data;
  location: Data;
  customer: string;
  files: Data[];
  reloadOrder: any;
  reloadLocation: any;
  errorHandler: any;
  clearMessages: any;
  setError: any;
}

interface FormValues {
  locationId: string;
  referenceNr: string;
  phone: string;
  orderer: string;
  callTime: string;
  description: string;
  notes: string;
  clientName: string;
  clientSignature: string;
  address: string;
  customer: string;
  status: string;
  completed: string;
}

interface WorkEntity {
  id?: string;
  type: string;
}

function OrderChangeForm(props: OrderChangeFormProps) {
  useDocumentTitle(['Tellimused', 'Muuda tellimus']);

  const { theme } = useTheme();
  const navigate = useNavigate();
  const sigPad: React.MutableRefObject<ReactSignatureCanvas | null> = useRef(null);

  const [showConfirm, toggleConfirm] = useState(false);
  const [locations] = useListAPI('locations');
  const [workEntity, setWorkEntity] = useState(undefined as WorkEntity | undefined);
  const [showWorkDialog, setShowWorkDialog] = useState(false);
  const [reloadWorkData, setReloadWorkData] = useState(false);

  function composeOrderData(form: FormValues) {
    //Add signature
    let clientSignature = props.order.j.clientSignature;

    if (!clientSignature && sigPad?.current?.isEmpty && !sigPad?.current?.isEmpty()) {
      clientSignature = sigPad.current.getTrimmedCanvas().toDataURL('image/png');
    }

    if (clientSignature) {
      form.clientSignature = clientSignature;
      form.status = 'signed';
    }

    return form;
  }

  function updateOrderData(data: any): Promise<any> {
    return changeEntity('orders', props.order, data)
      .then(() => {
        return props.reloadOrder();
      })
      .then(() => {
        window.scrollTo(0, 0);
      })
      .catch(props.errorHandler);
  }

  function handleChange(form: FormValues): Promise<any> {
    const data = composeOrderData(form);

    return updateOrderData(data);
  }

  async function handleSavedFiles(sf: SavedFile[]) {
    const additions = sf.map(f => {
      const data = {
        parentId: props.order.i,
        filename: f.originalname,
        hash: f.filename,
        mimeType: f.mimetype
      };
      return addEntity('attachments', data);
    });

    return Promise.all(additions)
      .then(() => {
        return props.reloadOrder();
      })
      .catch(props.errorHandler);
  }

  async function handleFileRemove(hash: string) {
    return deleteEntity('attachments', hash, 1).then(() => {
      return props.reloadOrder();
    });
  }

  function clearSigPad() {
    if (sigPad?.current) {
      sigPad.current.clear();
    }
    props.clearMessages();
  }

  function stopWorking(form: FormValues): Promise<any> {
    const data = composeOrderData(form);
    data.status = 'paused';

    return updateOrderData(data);
  }

  function completeOrder(e: any, form: FormValues, confirmed: boolean) {
    e.preventDefault();
    const data = composeOrderData(form);

    if (!data.clientSignature && !confirmed) {
      toggleConfirm(true);
      return;
    }

    data.status = 'done';
    data.completed = new Date().toISOString();

    return updateOrderData(data)
      .then(() => {
        if (props.order.j.fromEmail && props.order.j.referenceNr) {
          const emailData = {
            referenceNr: props.order.j.referenceNr,
            emailRef: props.order.j.emailRef
          };
          return sendEmail('emails/resolve', emailData);
        }
      })
      .catch(props.errorHandler);
  }

  function cancelOrder(form?: FormValues): Promise<any> {
    let data = {
      status: '',
      completed: ''
    };

    if (form) {
      data = composeOrderData(form);
    }

    data.status = 'canceled';
    data.completed = new Date().toISOString();

    return updateOrderData(data);
  }

  function openWorkModal(type: string, id?: string) {
    setWorkEntity({ id, type });
    setShowWorkDialog(true);
  }

  function closeWorkDialog(reload?: boolean) {
    setShowWorkDialog(false);
    if (reload) {
      // Changing property to force child component reload
      setReloadWorkData(!reloadWorkData);
    }
  }

  function closeModal() {
    toggleConfirm(false);
  }

  function clearErrors() {
    props.clearMessages();
  }

  const locationOptions = locations.map(location => ({
    label: location.j.name,
    value: location.i
  }));

  function handleDateChange(date: Date | null) {
    let value;

    if (date) {
      value = date.toISOString();
    } else {
      value = '';
    }

    setValue('callTime', value);
  }

  const defaultValues = {
    locationId: props.order.j.locationId,
    referenceNr: props.order.j.referenceNr,
    phone: props.order.j.phone,
    orderer: props.order.j.orderer,
    callTime: props.order.j.callTime,
    description: props.order.j.description,
    notes: props.order.j.notes,
    clientName: props.order.j.clientName,
    clientSignature: props.order.j.clientSignature,
    address: props.order.j.address,
    customer: props.order.j.customer,
    status: props.order.j.status,
    completed: props.order.j.completed
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    getFieldState,
    watch
  } = useForm<FormValues>({ defaultValues: defaultValues });

  const watchedLocation = watch('locationId');

  useEffect(() => {
    const selected = locations.find((location: any) => location.i === watchedLocation);

    if (!getFieldState('phone').isTouched && selected?.j['phone']) {
      setValue('phone', selected.j['phone']);
    }

    props.reloadLocation(watchedLocation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedLocation, locations]);

  return (
    <>
      <form onSubmit={handleSubmit(handleChange)} onChange={clearErrors}>
        <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: 4, mb: 2 }}>
          <Box
            sx={{
              display: 'flex',
              gap: 3,
              flexDirection: ['column', 'column', 'column', 'row']
            }}>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
              <FormControl>
                <FormControl.Label htmlFor="location-select">Objekt</FormControl.Label>
                <Controller
                  name="locationId"
                  control={control}
                  rules={{ required: { value: true, message: 'Määra asukoha objekt' } }}
                  render={({ field: { onChange, value } }) => (
                    <CustomSelect
                      inputId="location-select"
                      placeholder="Vali objekt..."
                      options={locationOptions}
                      value={locationOptions.filter(location => value === location.value)}
                      onChange={(event: any) => onChange(event.value)}
                      aria-errormessage={errors.locationId?.message}
                      aria-invalid={!!errors.locationId}
                    />
                  )}
                />
                {errors.locationId && (
                  <FormControl.Validation variant="error">{errors.locationId.message}</FormControl.Validation>
                )}
              </FormControl>

              <FormControl disabled>
                <FormControl.Label>Aadress</FormControl.Label>
                <TextInput
                  block
                  {...register('address')}
                  value={props.location.j.address ? props.location.j.address : ''}
                />
              </FormControl>

              <FormControl disabled>
                <FormControl.Label>Klient</FormControl.Label>
                <TextInput block {...register('customer')} value={props.customer ? props.customer : ''} />
              </FormControl>

              <FormControl>
                <FormControl.Label>Kliendi viide</FormControl.Label>
                <TextInput block {...register('referenceNr')} />
              </FormControl>
            </Box>

            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
              <FormControl>
                <FormControl.Label>Tellija</FormControl.Label>
                <TextInput
                  block
                  {...register('orderer', {
                    required: {
                      value: true,
                      message: 'Sisesta tellija andmed'
                    }
                  })}
                />

                {errors.orderer && (
                  <FormControl.Validation variant="error">{errors.orderer.message}</FormControl.Validation>
                )}
              </FormControl>

              <FormControl>
                <FormControl.Label>Telefon</FormControl.Label>
                <TextInput block {...register('phone')} />
              </FormControl>

              <FormControl>
                <FormControl.Label htmlFor="callTime">Väljakutse aeg</FormControl.Label>
                <Controller
                  name="callTime"
                  control={control}
                  rules={{ required: { value: true, message: 'Vali väljakutse aeg' } }}
                  render={({ field }) => (
                    <SingleDateTimePicker
                      name="callTime"
                      id="callTime"
                      value={field.value}
                      onChange={d => handleDateChange(d)}
                    />
                  )}
                />

                {errors.callTime && (
                  <FormControl.Validation variant="error">{errors.callTime.message}</FormControl.Validation>
                )}
              </FormControl>

              <FormControl sx={{ height: '57px', justifyContent: 'flex-end', alignItems: 'center' }}>
                <FormControl.Label htmlFor="files" visuallyHidden>
                  Failid
                </FormControl.Label>
                <FileUploader
                  block
                  setUploadError={props.setError}
                  apiErrorHandler={props.errorHandler}
                  handleSavedFiles={handleSavedFiles}
                  clearErrors={clearErrors}
                />
              </FormControl>
            </Box>
          </Box>

          {props.files.length > 0 && (
            <AttachmentGallery
              files={props.files.map(f => f.j)}
              errorHandler={props.errorHandler}
              handleFileRemove={handleFileRemove}
            />
          )}

          <FormControl>
            <FormControl.Label>Probleem</FormControl.Label>
            <Textarea
              {...register('description', { required: { value: true, message: 'Kirjelda tellija probleemi' } })}
              block
              rows={3}
              sx={{ overflow: 'auto', resize: 'vertical' }}
            />

            {errors.description && (
              <FormControl.Validation variant="error">{errors.description.message}</FormControl.Validation>
            )}
          </FormControl>

          <FormControl>
            <FormControl.Label>Märkused</FormControl.Label>
            <Textarea {...register('notes')} block rows={4} sx={{ overflow: 'auto', resize: 'vertical' }} />
          </FormControl>

          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <H2>Teostatud tööd</H2>

            <ActionMenu>
              <ActionMenu.Button>Lisa</ActionMenu.Button>
              <ActionMenu.Overlay>
                <ActionList>
                  <ActionList.Item onClick={() => openWorkModal('WorkProgress')}>Töötamine</ActionList.Item>
                  <ActionList.Item onClick={() => openWorkModal('DrivingData')}>Sõiduinfo</ActionList.Item>
                  <ActionList.Item onClick={() => openWorkModal('StockItem')}>Materjal</ActionList.Item>
                  <ActionList.Item onClick={() => openWorkModal('UsedSpare')}>Varuosa</ActionList.Item>
                </ActionList>
              </ActionMenu.Overlay>
            </ActionMenu>
          </Box>

          <WorkProgressList
            orderId={props.order.i}
            canChange={true}
            openWorkModal={openWorkModal}
            reload={reloadWorkData}
          />

          <H3>Kliendi allkiri</H3>

          <FormControl>
            <FormControl.Label>Vastuvõtja</FormControl.Label>
            <TextInput block {...register('clientName')} />
          </FormControl>

          <FormControl>
            <FormControl.Label>Allkiri</FormControl.Label>
            <Controller
              name="clientSignature"
              control={control}
              render={() => (
                <Box display="flex" className="signature-wrapper">
                  <Box>
                    <Box position="relative">
                      <Box position="absolute" onClick={clearSigPad} right={0} p={2}>
                        <Octicon icon={XIcon} size={24} color={theme!.colors.fg.muted} />
                      </Box>
                    </Box>

                    <ReactSignatureCanvas
                      penColor="grey"
                      clearOnResize={false}
                      minWidth={0.9}
                      maxWidth={0.9}
                      canvasProps={{
                        id: 'clientSignature',
                        className: 'signature-canvas'
                      }}
                      ref={sigPad}
                    />
                  </Box>
                </Box>
              )}
            />
          </FormControl>

          <FormFooter>
            <Button onClick={() => navigate('/orders')} leadingVisual={ArrowLeftIcon}>
              Tagasi
            </Button>

            <Button onClick={() => stopWorking(getValues())}>Ootele</Button>

            <Button variant="primary" type="submit">
              Salvesta
            </Button>

            <Button variant="danger" type="submit" onClick={(e: any) => completeOrder(e, getValues(), false)}>
              Lõpeta
            </Button>

            {getCurrentUserRole() === 'admin' && (
              <Button variant="danger" onClick={() => cancelOrder(getValues())}>
                Tühista
              </Button>
            )}
          </FormFooter>

          <ConfirmDialog
            showConfirm={showConfirm}
            closeModal={closeModal}
            onClick={(e: any) => completeOrder(e, getValues(), true)}>
            <Text as="p">Soovid tellimuse lõpetada ilma kliendi allkirjata.</Text>
            <Text as="p" fontWeight="bold">
              Pärast lõpetamist tellimust enam muuta ei saa!
            </Text>
            <Text as="p">Kas oled kindel, et soovid tellimuse lõpetada?</Text>
          </ConfirmDialog>
        </Box>
      </form>

      {showWorkDialog && (
        <WorkDataDialog
          show={showWorkDialog}
          id={workEntity?.id}
          orderId={props.order.i}
          type={workEntity!.type}
          closeDialog={closeWorkDialog}
        />
      )}
    </>
  );
}

export default OrderChangeForm;
