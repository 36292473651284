import restTypedAxios from 'restyped-axios';
import { CoreAPI } from 'kiisu-api-types/core';
import { getBearerToken } from './api';
import { ReportQuery } from 'kiisu-api-types/core.reports';

const coreAPI = restTypedAxios.create<CoreAPI>({
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
});

export function getInvoiceReport(params: { customerId?: string } & ReportQuery) {
  return coreAPI.get<'/core/v2/reports/invoice'>('/core/v2/reports/invoice', {
    params: params,
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function getSalaryReport(params: { userId?: string } & ReportQuery) {
  return coreAPI.get<'/core/v2/reports/salary'>('/core/v2/reports/salary', {
    params: params,
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function getReportCSVFile(type: 'invoice' | 'salary', params: { customerId?: string } & ReportQuery) {
  return coreAPI.get<'/core/v2/reports/csv/:type'>(`/core/v2/reports/csv/${type}`, {
    params: params,
    responseType: 'blob',
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}
