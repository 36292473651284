import { logout } from '../api/api';

export type ErrorHandler = (error: any) => void;

export function checkAPIError(error: any): string {
  console.error(error);

  if (error.response) {
    switch (error.response.status) {
      case 401:
        doLogout();
        break;
      case 403:
        return 'Kasutajal puudub õigus päringu teostamiseks.';
      case 409:
        return 'Salvestamine ebaõnnestus, andmed on vahepeal muutunud. Värskenda lehte.';
    }
  }

  return 'Päringu teostamisel ilmnes viga.';
}

function doLogout() {
  logout().then(() => {
    localStorage.clear();
    window.location.assign('/login');
  });
}

export function handleMultipleErrors(error: string, incoming: string, setError: any) {
  if (incoming && !error) {
    setError(incoming);
  }
}
