import React from 'react';
import ListTable from '../components/ListTable';
import ContentContainer from '../components/layout/ContentContainer';
import { useListAPI } from '../hooks/ApiHooks';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import PageHead from '../components/layout/PageHead';

function Customers() {
  useDocumentTitle(['Kliendid']);

  const [list, apiError] = useListAPI('customers');

  const headers = { name: 'Nimetus' };

  const rows = list.map(customer => ({
    id: customer.i,
    name: customer.j.name
  }));

  return (
    <ContentContainer xlarge>
      <PageHead title="Kliendid" add="/customers/add" errorMsg={apiError}></PageHead>
      <ListTable rows={rows} headers={headers} edit={{ key: 'name', url: '/customers/:id' }} />
    </ContentContainer>
  );
}

export default Customers;
