import React, { useEffect, useState } from 'react';
import { useListAPI } from '../../hooks/ApiHooks';
import { getOrderEvents } from '../../api/api';
import { checkAPIError } from '../../services/ErrorService';
import { Box, Octicon, Timeline } from '@primer/react';
import { GitCommitIcon, Icon, IssueClosedIcon, IssueOpenedIcon } from '@primer/octicons-react';
import moment from 'moment';
import {
  Changeset,
  findChangeByKey,
  getValueByKey,
  hasValue,
  isOldValueByKey,
  isValueByKey,
  keyExists
} from '../../utils/ChangesetParser';
import { Banner } from '@primer/react/experimental';

interface EventData {
  id: string;
  previousid: string;
  objectid: string;
  dt: string;
  u: {
    id: string;
    role: string;
  };
  t: string;
  v: number;
  a: string;
  diff: Changeset[];
}

interface Activity {
  id: string;
  icon: Icon;
  date: string;
  lines: string[];
}

function OrderActivityLog(props: { orderId: string; update: boolean }) {
  const [error, setError] = useState('');
  const [activityList, setActivityList] = useState(new Array<Activity>());
  const [users, userError] = useListAPI('users');

  useEffect(() => {
    const getEvents = async () => {
      try {
        const result = await getOrderEvents(props.orderId);
        // Do not process events where nothing is updated, except DELETE actions.
        const filteredEvents = result.data.rows.filter((r: EventData) => r.diff.length > 0 || r.a === 'DELETE');
        processEventData(filteredEvents);
      } catch (e) {
        setError(checkAPIError(e));
      }
    };

    // Make sure orderId has been set and users have been loaded
    if (props.orderId && users.length > 0) {
      getEvents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.orderId, props.update, users]);

  function processEventData(rows: EventData[]) {
    let list: Activity[] = [];

    rows.forEach(r => {
      const activity: Activity = {
        id: r.id,
        date: getActionDate(r),
        icon: getActionSign(r),
        lines: getDescriptions(r)
      };
      list.push(activity);
    });

    setActivityList(list);
  }

  function getActionDate(event: EventData) {
    if (isOrderClosedEvent(event) && keyExists(event.diff, 'completed')) {
      return getValueByKey(event.diff, 'completed');
    }

    return event.dt;
  }

  function getActionSign(event: EventData) {
    if (isNewOrderEvent(event)) {
      return IssueOpenedIcon;
    }

    if (isOrderClosedEvent(event) || isOrderCanceledEvent(event)) {
      return IssueClosedIcon;
    }

    return GitCommitIcon;
  }

  function getDescriptions(event: EventData): string[] {
    let result: string[] = [];

    const user = getUserName(event.u.id);

    if (isNewOrderEvent(event)) {
      result.push(`${user} lisas uue tellimuse`);
    }

    if (isAddedAttachmentEvent(event)) {
      result.push(`${user} lisas manuse ${getValueByKey(event.diff, 'filename')}`);
    }

    if (isRemovedAttachmentEvent(event)) {
      result.push(`${user} eemaldas manuse ${getValueByKey(event.diff, 'filename')}`);
    }

    if (isSignatureRemoved(event.diff)) {
      result.push(`${user} tühistas kliendi allkirja`);
    }

    if (isSignatureAdded(event.diff)) {
      const client = getValueByKey(event.diff, 'clientName');
      result.push(`${client} allkirjastas tellimuse`);
    }

    if (isOrderStatusChange(event)) {
      // Order has been reopened after previously being closed or signed
      if (isOrderReopenEvent(event.diff)) {
        result.push(`${user} võttis tellimuse uuesti töösse`);
      }
      // Order has been taken into work
      if (isValueByKey(event.diff, 'status', 'inProgress') && !isOrderReopenEvent(event.diff)) {
        result.push(`${user} võttis tellimuse töösse`);
      }
      // Order work has been paused
      if (isValueByKey(event.diff, 'status', 'paused') && isOldValueByKey(event.diff, 'status', 'inProgress')) {
        result.push(`${user} peatas tellimuse töö`);
      }
      // Order work is done
      if (isValueByKey(event.diff, 'status', 'done')) {
        result.push(`${user} lõpetas tellimuse`);
      }
      // Order has been canceled
      if (isValueByKey(event.diff, 'status', 'canceled')) {
        result.push(`${user} tühistas tellimuse`);
      }
    }

    if (isOrderDataChange(event)) {
      result.push(`${user} muutis tellimuse andmeid`);
    }

    if (event.a === 'INSERT' && typeMap[event.t]) {
      result.push(`${user} lisas ${typeMap[event.t]}`);
    }

    if (event.a === 'UPDATE' && typeMap[event.t]) {
      result.push(`${user} uuendas ${typeMap[event.t]} andmeid`);
    }

    if (event.a === 'DELETE' && typeMap[event.t]) {
      result.push(`${user} kustutas ${typeMap[event.t]}`);
    }

    if (result.length === 0) {
      console.log('\n');
      console.log(event);
      console.log(event.diff);
      console.log('\n');
    }

    return result;
  }

  function getUserName(id: string): string {
    const found = users.find(u => u.i === id);

    if (found) {
      return `${found.j.firstName} ${found.j.lastName}`;
    }

    return '';
  }

  function isNewOrderEvent(event: EventData): boolean {
    return event.a === 'INSERT' && event.t === 'order';
  }

  function isOrderClosedEvent(event: EventData): boolean {
    return event.a === 'UPDATE' && event.t === 'order' && isValueByKey(event.diff, 'status', 'done');
  }

  function isOrderCanceledEvent(event: EventData): boolean {
    return event.a === 'UPDATE' && event.t === 'order' && isValueByKey(event.diff, 'status', 'canceled');
  }

  function isAddedAttachmentEvent(event: EventData): boolean {
    return event.a === 'INSERT' && event.t === 'attachment';
  }

  function isRemovedAttachmentEvent(event: EventData): boolean {
    return event.a === 'DELETE' && event.t === 'attachment';
  }

  function isOrderStatusChange(event: EventData) {
    return event.t === 'order' && event.a === 'UPDATE' && keyExists(event.diff, 'status');
  }

  function isOrderReopenEvent(diff: Changeset[]) {
    return (
      isValueByKey(diff, 'status', 'inProgress') &&
      (isOldValueByKey(diff, 'status', 'done') || isOldValueByKey(diff, 'status', 'signed'))
    );
  }

  function isSignatureAdded(diff: Changeset[]) {
    return hasValue(diff, 'clientSignature');
  }

  function isSignatureRemoved(diff: Changeset[]) {
    const cs = findChangeByKey(diff, 'clientSignature');
    return cs && !cs.value && cs.oldValue;
  }

  function isOrderDataChange(event: EventData) {
    return event.a === 'UPDATE' && event.t === 'order' && !isOrderStatusChange(event);
  }

  const typeMap: { [key: string]: string } = {
    workProgress: 'töötamise',
    drivingData: 'sõiduinfo',
    stockItem: 'materjali'
  };

  return (
    <Box px={2} py={3}>
      {(error || userError) && (
        <Box sx={{ width: '100%', mb: 4 }}>
          <Banner title={error ? error : userError} variant="critical"></Banner>
        </Box>
      )}
      {activityList.length > 0 && (
        <Timeline>
          {activityList.map(a => {
            return (
              <Timeline.Item key={a.id}>
                <Timeline.Badge>
                  <Octicon icon={a.icon} />
                </Timeline.Badge>
                <Timeline.Body>
                  <ActivityBody date={a.date} lines={a.lines} />
                </Timeline.Body>
              </Timeline.Item>
            );
          })}
        </Timeline>
      )}
    </Box>
  );
}

export default OrderActivityLog;

function ActivityBody(props: { date: string; lines: string[] }) {
  const dt = moment(props.date).format('DD.MM.YYYY HH:mm:ss');

  return (
    <Box>
      <p>{dt}</p>
      {props.lines.map((l, index) => (
        <p key={index}>{l}</p>
      ))}
    </Box>
  );
}
