import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { getEntity, login } from '../api/api';
import { setCurrentUser } from '../services/CurrentUserService';
import { Box, Button, FormControl, TextInput } from '@primer/react';
import { Banner } from '@primer/react/experimental';
import logo from '../assets/cat-icon-white.svg';
import { useNavigate } from 'react-router-dom';

interface FormValues {
  username: string;
  password: string;
}

function Login() {
  const navigate = useNavigate();
  const [apiError, setApiError] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<FormValues>();

  async function handleLogin(form: FormValues) {
    return login(form.username, form.password)
      .then((res: any) => {
        if (!res.data.jwt) {
          throw new Error('Kasutaja sisenemine ebaõnnestus');
        }
        localStorage.setItem('apptoken', res.data.jwt);
        return getEntity('users/current/user');
      })
      .then((user: any) => {
        return setCurrentUser(user.data);
      })
      .then(() => {
        navigate('/orders', { replace: true });
      })
      .catch((error: any) => {
        if (error.message.endsWith('400')) {
          setApiError('Vale kasutajanimi või salasõna');
        } else {
          setApiError(error.message);
        }
      });
  }

  function clearApiError() {
    setApiError('');
  }

  return (
    <Box as="main">
      <Box display="flex" mb={2} justifyContent="center" width={1} mt={12}>
        <Box
          color="white"
          borderWidth="1px"
          borderStyle="solid"
          borderColor="border.default"
          borderRadius={2}
          width={64}
          height={64}
          bg="rgb(68, 77, 86)">
          <img src={logo} width="56" height="56" alt="Kiisu" style={{ margin: '3px' }} />
        </Box>
      </Box>

      <form onSubmit={handleSubmit(handleLogin)} onChange={() => clearApiError()}>
        <Box display="flex" flexDirection="column" mt={4} justifyContent="center" alignItems="center" width={1}>
          <Box sx={{ width: 340 }}>
            {apiError && (
              <Box sx={{ width: '100%', mb: 4 }}>
                <Banner title={apiError} variant="critical"></Banner>
              </Box>
            )}

            <Box
              sx={{
                display: 'grid',
                gap: 4,
                borderWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'border.default',
                borderRadius: 5,
                p: 4
              }}>
              <FormControl>
                <FormControl.Label>Kasutajanimi</FormControl.Label>
                <TextInput
                  {...register('username', {
                    required: {
                      value: true,
                      message: 'Kasutajanimi on kohustuslik'
                    }
                  })}
                  block={true}
                />
                {errors.username && (
                  <FormControl.Validation variant="error">{errors.username.message}</FormControl.Validation>
                )}
              </FormControl>

              <FormControl>
                <FormControl.Label>Parool</FormControl.Label>
                <TextInput
                  type="password"
                  {...register('password', {
                    required: {
                      value: true,
                      message: 'Parool on kohustuslik'
                    }
                  })}
                  block={true}
                />
                {errors.password && (
                  <FormControl.Validation variant="error">{errors.password.message}</FormControl.Validation>
                )}
              </FormControl>

              <Button variant="primary" type="submit">
                Sisene
              </Button>
            </Box>
          </Box>
        </Box>
      </form>
    </Box>
  );
}

export default Login;
