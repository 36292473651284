const privilegesConf = require('../configs/privileges.json');

export async function setCurrentUser(userData: any) {
  const user = {
    id: userData.i,
    firstName: userData.j.firstName,
    lastName: userData.j.lastName,
    email: userData.j.username,
    role: userData.j.role
  };
  localStorage.setItem('currentUser', JSON.stringify(user));
  return;
}

export function getCurrentUser() {
  const userInfo = localStorage.getItem('currentUser');
  if (userInfo !== null) {
    return JSON.parse(userInfo);
  }
  return null;
}

export function getCurrentUserId() {
  if (getCurrentUser() != null) {
    return getCurrentUser().id;
  }
  return null;
}

export function getCurrentUserRole() {
  if (getCurrentUser() != null) {
    return getCurrentUser().role;
  }
  return null;
}

export function getCurrentUserEmail() {
  if (getCurrentUser() != null) {
    return getCurrentUser().email;
  }
  return null;
}

export function hasPrivileges(privileges: string[]) {
  const userPrivileges: string[] = privilegesConf[getCurrentUserRole()];

  if (userPrivileges && userPrivileges.length > 0 && privileges && privileges.length > 0) {
    return !!privileges.find(priv => userPrivileges.includes(priv));
  }

  return false;
}

export function isAuthenticated() {
  return getCurrentUser() !== null;
}
