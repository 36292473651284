import React, { useEffect, useState } from 'react';
import { getDevices, getEntities } from '../api/api';
import ListTable from '../components/ListTable';
import { checkAPIError } from '../services/ErrorService';
import ContentContainer from '../components/layout/ContentContainer';
import { DeviceStored } from 'kiisu-api-types/devices';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import PageHead from '../components/layout/PageHead';

export default function Devices() {
  useDocumentTitle(['Seadmed']);

  const [apiError, setApiError] = useState(undefined as undefined | string);
  const [devices, setDevices] = useState([] as DeviceStored[]);
  const [locations, setLocations] = useState({} as { [key: string]: string });

  useEffect(() => {
    getDevices()
      .then(results => {
        setDevices(results.data.items);
        return getEntities('locations');
      })
      .then(results => {
        setLocations(Object.assign({}, ...results.data.map(location => ({ [location.i]: location.j.name }))));
      })
      .catch(error => {
        const err = checkAPIError(error);
        setApiError(err);
      });
  }, []);

  function getIPAddress(intIp: string, extIp: string) {
    if (intIp && extIp) {
      return intIp + '/' + extIp;
    }
    if (intIp) {
      return intIp;
    }
    return extIp;
  }

  return (
    <ContentContainer xlarge>
      <PageHead title="Seadmed" add="/devices/add" errorMsg={apiError}></PageHead>
      <ListTable
        headers={{
          name: 'Nimetus',
          address: 'Asukoht',
          ip: 'IP',
          status: 'Staatus',
          softwareVersion: 'Tarkvara'
        }}
        rows={devices.map(row => ({
          id: row.metadata.id,
          name: row.name,
          address: locations[row.locationId],
          ip: getIPAddress(row.intIp, row.extIp),
          status: row.status?.status,
          // @ts-ignore
          softwareVersion: row.status?.crawl?.softwareVersion
        }))}
        edit={{ key: 'name', url: '/devices/:id' }}
        hideOnSmall={['ip']}
      />
    </ContentContainer>
  );
}
