import React from 'react';
import { Box, Button } from '@primer/react';
import { H1 } from '../utilities/typography';
import SearchInput from '../SearchInput';
import { Banner } from '@primer/react/experimental';
import { useNavigate } from 'react-router-dom';

function PageHead(props: {
  title?: string | React.ReactNode;
  add?: string;
  errorMsg?: string;
  successMsg?: string;
  search?: any;
}) {
  const navigate = useNavigate();

  return (
    <>
      {props.title ? (
        <Box
          sx={{
            display: 'flex',
            my: 3,
            justifyContent: 'flex-end',
            alignItems: 'center',
            flexWrap: 'wrap-reverse',
            gap: 2
          }}>
          <Box
            sx={{
              minWidth: '257px',
              display: 'flex',
              flex: '1',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
            <H1>{props.title}</H1>
            {props.add && <Button onClick={() => navigate(props.add!)}>Lisa</Button>}
          </Box>

          {props.search && (
            <Box width={[1, '216px']}>
              <SearchInput doSearch={props.search} />
            </Box>
          )}
        </Box>
      ) : null}

      {props.errorMsg && (
        <Box sx={{ width: '100%', mb: 4 }}>
          <Banner title={props.errorMsg} variant="critical"></Banner>
        </Box>
      )}

      {props.successMsg && (
        <Box sx={{ width: '100%', mb: 4 }}>
          <Banner title={props.successMsg} variant="success"></Banner>
        </Box>
      )}
    </>
  );
}

export default PageHead;
