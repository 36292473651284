import React, { useEffect, useState } from 'react';
import { getAlarms } from '../api/api';
import ListTable from '../components/ListTable';
import { checkAPIError } from '../services/ErrorService';
import ContentContainer from '../components/layout/ContentContainer';
import Moment from 'react-moment';
import { AlarmStored } from 'kiisu-api-types/devices';
import { Octicon, Tooltip } from '@primer/react';
import { AlertIcon } from '@primer/octicons-react';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import PageHead from '../components/layout/PageHead';

export default function Alarms() {
  useDocumentTitle(['Alarmid']);

  const [apiError, setApiError] = useState(undefined as undefined | string);
  const [entity, setEntity] = useState([] as AlarmStored[]);

  useEffect(() => {
    getAlarms()
      .then(results => {
        setEntity(results.data.items);
      })
      .catch(error => {
        const err = checkAPIError(error);
        setApiError(err);
      });
  }, []);

  return (
    <ContentContainer xlarge>
      <PageHead title="Alarmid" errorMsg={apiError}></PageHead>
      <ListTable
        headers={{
          name: 'Nimi seadmest',
          deviceName: 'Kontroller',
          deviceAddress: 'Aadress',
          alarmType: 'Alarmi tüüp',
          entered: 'Sisestatud',
          occurred: 'Toimumise aeg',
          status: 'Staatus',
          cleared: 'Klaaritud'
        }}
        rows={entity.map(row => ({
          id: row.metadata.id,
          // kui alarmil pole locationit küljes siis teda ei kustutata ja jääb rippuma
          name: (
            <span>
              {row.storeName}{' '}
              {!row.locationId && (
                <Tooltip aria-label="Nimi seadmes ja siin ei ühti! See alarm võib jääda siia rippuma!">
                  <Octicon icon={AlertIcon} />
                </Tooltip>
              )}
            </span>
          ),
          deviceName: row.deviceName,
          deviceAddress: row.address,
          // @ts-ignore
          alarmType: row.alarmTypeName || row.typeName,
          entered: <Moment format="DD.MM.YYYY HH:mm">{row.metadata.creationTimestamp}</Moment>,
          occurred: <Moment format="DD.MM.YYYY HH:mm">{row.occurred}</Moment>,
          status: row.status,
          cleared: row.cleared ? <Moment format="DD.MM.YYYY HH:mm">{row.cleared}</Moment> : ''
        }))}
        hideOnSmall={['deviceAddress', 'entered', 'occurred', 'status', 'cleared']}
      />
    </ContentContainer>
  );
}
