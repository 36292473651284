import React, { useEffect, useState } from 'react';
import { addEntity, changeEntity, Data, getEntities, getEntityById } from '../api/api';
import { Box, Button, FormControl, TextInput } from '@primer/react';
import { useNavigate, useParams } from 'react-router';
import { Controller, useForm } from 'react-hook-form';
import { ArrowLeftIcon } from '@primer/octicons-react';
import CustomSelect from '../components/CustomSelect';
import { checkAPIError } from '../services/ErrorService';
import { useDocumentTitle } from '../hooks/useDocumentTitle';
import { Banner } from '@primer/react/experimental';
import FormFooter from '../components/FormFooter';
import ContentContainer from '../components/layout/ContentContainer';
import PageHead from '../components/layout/PageHead';

interface FormValues {
  i: string;
  firstName: string;
  lastName: string;
  username: string;
  password: string | undefined;
  passwordConfirmation: string | undefined;
  phone: string;
  role: string;
}

function UserForm(props: { preloadedValues?: FormValues; error: string | undefined }) {
  useDocumentTitle(['Kasutajad', 'Kasutaja']);

  const [apiError, setApiError] = useState(props.error);
  const [userRoles, setUserRoles] = useState(undefined as { [key: string]: string } | undefined);

  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    control
  } = useForm<FormValues>({ defaultValues: props.preloadedValues });

  useEffect(() => {
    getEntities('user-roles')
      .then(results => {
        setUserRoles(Object.assign({}, ...results.data.map(role => ({ [role.j.role]: role.j.text }))));
      })
      .catch(error => setApiError(checkAPIError(error)));
  }, []);

  async function onSubmit(form: FormValues) {
    if (form.password !== form.passwordConfirmation) {
      setError('passwordConfirmation', { message: 'Salasõnad ei ühti' });
      return;
    }

    delete form.passwordConfirmation;

    if (props.preloadedValues && props.preloadedValues.i) {
      changeEntity('users', form, form)
        .then(() => navigate('/users'))
        .catch(error => {
          setApiErrorMessage(error);
        });
    } else {
      addEntity('users', form)
        .then(() => navigate('/users'))
        .catch(error => {
          setApiErrorMessage(error);
        });
    }
  }

  const setApiErrorMessage = (error: any) => {
    if (error.response?.status === 400 && error.response?.data.startsWith('Unique constraint violation')) {
      setApiError('Sellise kasutajanimega kasutaja on juba olemas');
    } else {
      setApiError(checkAPIError(error));
    }
  };

  function clearApiError() {
    setApiError('');
  }

  const roleOptions = Object.keys(userRoles ? userRoles : []).map(data => ({
    label: userRoles![data],
    value: data
  }));

  return (
    <form onSubmit={handleSubmit(onSubmit)} onChange={() => clearApiError()}>
      <Box sx={{ display: 'grid', gap: 3, py: 3 }}>
        {apiError && <Banner title={apiError} variant="critical"></Banner>}

        <FormControl>
          <FormControl.Label>Eesnimi</FormControl.Label>
          <TextInput block={true} {...register('firstName', { required: { value: true, message: 'Määra eesnimi' } })} />
          {errors.firstName && (
            <FormControl.Validation variant="error">{errors.firstName.message}</FormControl.Validation>
          )}
        </FormControl>

        <FormControl>
          <FormControl.Label>Perekonnanimi</FormControl.Label>
          <TextInput
            block={true}
            {...register('lastName', { required: { value: true, message: 'Määra perekonnanimi' } })}
          />
          {errors.lastName && (
            <FormControl.Validation variant="error">{errors.lastName.message}</FormControl.Validation>
          )}
        </FormControl>

        <FormControl>
          <FormControl.Label>Kasutajanimi</FormControl.Label>
          <TextInput
            block={true}
            {...register('username', { required: { value: true, message: 'Määra kasutajanimi' } })}
          />
          {errors.username && (
            <FormControl.Validation variant="error">{errors.username.message}</FormControl.Validation>
          )}
        </FormControl>

        <FormControl>
          <FormControl.Label>Salasõna</FormControl.Label>
          <TextInput
            block={true}
            type="password"
            {...register('password', { required: { value: !props.preloadedValues?.i, message: 'Määra parool' } })}
          />
          {errors.password && (
            <FormControl.Validation variant="error">{errors.password.message}</FormControl.Validation>
          )}
        </FormControl>

        <FormControl>
          <FormControl.Label>Salasõna kinnitamine</FormControl.Label>
          <TextInput
            block={true}
            type="password"
            {...register('passwordConfirmation', {
              required: { value: !props.preloadedValues?.i, message: 'Kinnita parool' }
            })}
          />
          {errors.passwordConfirmation && (
            <FormControl.Validation variant="error">{errors.passwordConfirmation.message}</FormControl.Validation>
          )}
        </FormControl>

        <FormControl>
          <FormControl.Label>Telefon</FormControl.Label>
          <TextInput block={true} {...register('phone')} />
          {errors.phone && <FormControl.Validation variant="error">{errors.phone.message}</FormControl.Validation>}
        </FormControl>

        <FormControl>
          <FormControl.Label htmlFor="role-select">Roll</FormControl.Label>
          <Controller
            name="role"
            control={control}
            rules={{ required: { value: true, message: 'Vali roll' } }}
            render={({ field: { onChange, value } }) => (
              <CustomSelect
                inputId="role-select"
                placeholder="Vali roll..."
                value={roleOptions.filter(c => value === c.value)}
                onChange={val => onChange(val!.value)}
                options={roleOptions}
                aria-errormessage={errors.role?.message}
                aria-invalid={!!errors.role}
              />
            )}
          />

          {errors.role && <FormControl.Validation variant="error">{errors.role.message}</FormControl.Validation>}
        </FormControl>

        <FormFooter>
          <Button onClick={() => navigate('/users')} leadingVisual={ArrowLeftIcon}>
            Tagasi
          </Button>

          <Button variant="primary" type="submit">
            {props.preloadedValues ? 'Muuda' : 'Lisa'}
          </Button>
        </FormFooter>
      </Box>
    </form>
  );
}

function UserChangePage() {
  const { id } = useParams();
  const [apiError, setApiError] = useState(undefined as string | undefined);
  const [entity, setEntity] = useState(undefined as Data | undefined);

  function dataToFormData(entity?: Data) {
    if (entity) {
      return {
        i: entity.i,
        v: entity.v,
        t: entity.t,
        firstName: entity.j.firstName,
        lastName: entity.j.lastName,
        username: entity.j.username,
        password: entity.j.password ? entity.j.password : '',
        passwordConfirmation: entity.j.password ? entity.j.password : '',
        phone: entity.j.phone,
        role: entity.j.role
      };
    }
  }

  useEffect(() => {
    if (id) {
      getEntityById('users', id)
        .then(results => {
          setEntity(results.data);
        })
        .catch(error => setApiError(checkAPIError(error)));
    }
  }, [id]);

  return (
    <ContentContainer>
      <PageHead title={id ? 'Muuda kasutaja andmeid' : 'Lisa uus kasutaja'}></PageHead>
      {!id || entity ? <UserForm preloadedValues={dataToFormData(entity)} error={apiError} /> : <></>}
    </ContentContainer>
  );
}

export default UserChangePage;
