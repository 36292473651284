import React from 'react';
import { SearchIcon } from '@primer/octicons-react';
import { TextInput } from '@primer/react';

type SearchEvent = (input: string) => any;

function SearchInput(props: { doSearch: SearchEvent }) {
  return (
    <TextInput
      width="100%"
      leadingVisual={SearchIcon}
      aria-label="Search"
      onKeyPress={(event: any) => {
        if (event.key === 'Enter') {
          event.target.blur();
          props.doSearch(event.target.value);
        }
      }}
    />
  );
}

export default SearchInput;
