import React from 'react';
import { Link } from '@primer/react';
import { Blankslate, BlankslateAction, BlankslateHeading, BlankslateIcon } from '../blankslate';
import { NoEntryIcon } from '@primer/octicons-react';
import { ContainerMd } from './container';

export function ErrorPage(props: { message: string }) {
  return (
    <ContainerMd>
      <Blankslate large={true} spacious={true}>
        <BlankslateIcon icon={NoEntryIcon} size={24} />
        <BlankslateHeading>{props.message}</BlankslateHeading>
        <BlankslateAction>
          <Link href="/public">Tagasi pealehele.</Link>
        </BlankslateAction>
      </Blankslate>
    </ContainerMd>
  );
}
