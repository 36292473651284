import { Box, themeGet, useTheme } from '@primer/react';
import styled from 'styled-components';
import { ContainerXl } from './container';

function Footer() {
  const { theme } = useTheme();

  return (
    <ContainerXl>
      <Box sx={{ borderTop: '1px solid', borderColor: theme!.colors.border.muted, py: 6, mt: 6, px: 3 }}>
        <FooterOcticon href="http://zerotech.ee">
          <Box display="flex" alignItems="center">
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 1700.000000 1622.000000"
              preserveAspectRatio="xMidYMid meet"
              style={{ fill: 'currentcolor', marginRight: 8 }}>
              <g transform="translate(0.000000,1622.000000) scale(0.100000,-0.100000)" stroke="none">
                <path
                  d="M7705 15700 c-542 -35 -1169 -147 -1733 -309 -302 -88 -798 -269
-1052 -386 -557 -256 -929 -469 -1395 -800 -547 -388 -1055 -874 -1525 -1455
-803 -996 -1304 -2122 -1494 -3360 -78 -506 -101 -865 -93 -1405 6 -366 12
-478 42 -790 34 -341 132 -847 235 -1208 209 -736 569 -1494 1026 -2163 254
-373 608 -792 948 -1121 144 -140 314 -289 320 -282 1 2 370 555 819 1228
l817 1223 -63 87 c-462 633 -747 1315 -882 2110 -28 165 -64 511 -76 737 -20
384 13 915 81 1308 91 518 290 1079 549 1541 283 506 730 1037 1176 1398 108
88 365 270 445 317 342 197 585 306 905 403 349 107 608 153 1018 183 203 15
271 16 551 5 175 -6 362 -16 415 -22 170 -19 473 -70 590 -101 141 -36 413
-126 502 -166 l67 -30 375 556 c207 307 560 831 786 1165 226 335 411 612 411
616 0 4 -44 25 -97 46 -54 21 -143 57 -198 80 -255 108 -744 271 -1075 359
-377 100 -896 189 -1300 222 -214 18 -900 26 -1095 14z"
                />
                <path
                  d="M12188 12829 l-816 -1211 31 -36 c18 -20 59 -66 93 -103 534 -589
912 -1299 1094 -2054 104 -434 152 -859 152 -1344 -1 -359 -37 -787 -88 -1046
-158 -797 -422 -1410 -869 -2019 -174 -237 -262 -340 -452 -528 -196 -195
-280 -267 -474 -414 -457 -344 -939 -571 -1499 -707 -450 -109 -779 -143
-1305 -134 -541 9 -898 61 -1355 199 -186 56 -336 115 -532 210 -92 45 -170
80 -172 77 -17 -19 -1536 -2324 -1536 -2331 0 -18 678 -330 960 -441 281 -110
698 -231 1079 -312 861 -183 1629 -227 2461 -141 676 70 1318 227 2060 501
245 91 747 328 1020 482 397 225 878 555 1205 828 291 243 339 287 560 509
516 517 915 1057 1237 1676 268 514 464 1012 631 1605 33 117 123 527 147 674
78 462 103 789 104 1311 1 498 -14 717 -80 1165 -165 1129 -627 2274 -1298
3215 -390 547 -922 1120 -1295 1393 -46 34 -119 90 -162 125 -43 34 -79 62
-81 62 -2 0 -371 -545 -820 -1211z"
                />
              </g>
            </svg>
            <span style={{ fontSize: 12, color: theme!.colors.fg.muted }}>
              © {new Date().getFullYear()} Zero Technologies
            </span>
          </Box>
        </FooterOcticon>
      </Box>
    </ContainerXl>
  );
}

const FooterOcticon = styled.a`
  color: ${themeGet('colors.fg.subtle')};
  :hover {
    color: ${themeGet('colors.fg.muted')};
    text-decoration: none;
  }
`;

export default Footer;
