import { KiisuAPI } from 'kiisu-api-types';
import axios from 'axios';
import restTypedAxios from 'restyped-axios';
import { DeviceNew, DeviceStored } from 'kiisu-api-types/devices';

const axiosInstance = axios.create({
  baseURL: '/rest/',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
});

const kiisuAPI = restTypedAxios.create<KiisuAPI>({
  baseURL: '/rest/',
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json'
  }
});

export function getBearerToken(): string {
  const token = localStorage.getItem('apptoken');
  if (token) {
    return token;
  }
  return '';
}

export interface Data {
  i: string;
  t: string;
  dt: string;
  j: any;
  v: number;
}

export interface LimitedData {
  rowCount: string;
  rows: Data[];
}

interface RequestConfig {
  params: {
    offset: string;
    limit: number;
    [key: string]: string | number;
  };
  headers: {
    Authorization: string;
    'If-None-Match'?: string;
  };
}

export function getEntity(path: string) {
  return axiosInstance.get<Data>(path, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function getEntityById(path: string, id: string) {
  return axiosInstance.get<Data>(path + '/' + id, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function getEntities(path: string, params?: any) {
  return axiosInstance.get<Data[]>(path, {
    params,
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function getAlarms() {
  return kiisuAPI.request({
    url: '/devices/v1/alarms',
    params: {
      interval: 30
    },
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function getDevices() {
  return kiisuAPI.request({
    url: '/devices/v1/devices',
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function getDevice(id: string) {
  return kiisuAPI.get<'/devices/v1/devices/:id'>(`/devices/v1/devices/${id}`, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function postDevice(data: DeviceNew) {
  return kiisuAPI.post('/devices/v1/devices', data, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function patchDevice(device: DeviceStored) {
  return kiisuAPI.patch<'/devices/v1/devices/:id'>(`/devices/v1/devices/${device.metadata.id}`, device, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function deleteDevice(id: string, resourceVersion: number) {
  return kiisuAPI.delete<'/devices/v1/devices/:id/:resourceVersion'>(`/devices/v1/devices/${id}/${resourceVersion}`, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function getEntitiesByLimit(path: string, asof: string, limit: number, orderBy?: string, etag?: string) {
  let config: RequestConfig = {
    params: {
      offset: asof,
      limit: limit
    },
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  };

  if (orderBy) config.params.orderBy = orderBy;

  if (etag) config.headers['If-None-Match'] = etag;

  return axiosInstance.get<LimitedData>(path, config);
}

export function addEntity(path: string, data: any) {
  return axiosInstance.post(path, data, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function changeEntity(path: string, entity: any, data: any) {
  return axiosInstance.patch(
    path + '/' + entity.i,
    {
      i: entity.i,
      t: entity.t,
      j: data,
      v: entity.v
    },
    {
      headers: {
        Authorization: 'Bearer ' + getBearerToken()
      }
    }
  );
}

export function changeMyData(entity: any, data: any) {
  return axiosInstance.patch(
    'users/current/user',
    {
      i: entity.i,
      t: entity.t,
      j: data,
      v: entity.v
    },
    {
      headers: {
        Authorization: 'Bearer ' + getBearerToken()
      }
    }
  );
}

export function getEntitiesByReference(path: string, label: string, value: string) {
  return axiosInstance.get<Data[]>(path + '?' + label + '=' + value, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function getEntitiesByReferenceAndLimit(
  path: string,
  label: string,
  value: string,
  asof: string,
  limit: number,
  orderBy?: string,
  etag?: string
) {
  let config: RequestConfig = {
    params: {
      offset: asof,
      limit: limit
    },
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  };

  config.params[label] = value;

  if (orderBy) config.params.orderBy = orderBy;

  if (etag) config.headers['If-None-Match'] = etag;

  return axiosInstance.get<LimitedData>(path, config);
}

export function deleteEntity(path: string, id: string, version: number) {
  return axiosInstance.delete(path + '/' + id + '?v=' + version, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function login(username: string, password: string) {
  return axiosInstance.post('auth/login', {
    username: username,
    password: password
  });
}

export function logout() {
  return axiosInstance.post(
    'auth/logout',
    {},
    {
      headers: {
        Authorization: 'Bearer ' + getBearerToken()
      }
    }
  );
}

export function uploadFile(path: string, file: File) {
  const formData = new FormData();
  formData.append('attachment', file);
  return axiosInstance.post(path, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function retrieveFile(path: string, hash: string) {
  return axiosInstance.get(path + '/' + hash, {
    responseType: 'blob',
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function sendEmail(path: string, data: any) {
  return axiosInstance.post(path, data, {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export interface SalaryRow {
  id: string; // .i ehk workProgress.id või drivingData.id
  customerid: string; // .orderId-order.locationId-location.customerId-customer.i
  client: string; // .orderId-order.locationId-location.customerId-customer.name
  location: string; // .orderId-order.locationId-location.name
  orderid: string; // .orderId
  t: string; // .t
  dt: string; // .dt
  referencenr: string; // .orderId-order.referenceNr
  description: string; // workProgress.description või drivingData.address
  amount: string; // workProgress."duration" või drivingData.drivingHours
}

export function getReportRows(
  type: string,
  params: {
    startDate: string | undefined;
    endDate: string | undefined;
    customerId: string | undefined;
  }
) {
  return axiosInstance.get<{ rows: SalaryRow[] }>('/reports/' + type, {
    params,
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}

export function getCSVFile(
  reportType: string,
  rows: any,
  startDate: string | undefined,
  endDate: string | undefined,
  customer: string | undefined
) {
  return axiosInstance.post(
    '/reports/csv/' + reportType,
    {
      startDate,
      endDate,
      customer,
      rows
    },
    {
      responseType: 'blob',
      headers: {
        Authorization: 'Bearer ' + getBearerToken()
      }
    }
  );
}

export function getOrderEvents(orderId: string) {
  return axiosInstance.get<any>('orders/' + orderId + '/events', {
    headers: {
      Authorization: 'Bearer ' + getBearerToken()
    }
  });
}
