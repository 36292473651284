import React from 'react';
import { Box, Text } from '@primer/react';
import { Link } from 'react-router-dom';

interface RowData {
  id: string;

  [key: string]: any;
}

interface ListProps {
  headers: { [key: string]: string };
  rows: RowData[];
  edit?: { key: string; url: string };
  hideOnSmall?: string[];
}

/**
 * Returns Table view for object data list
 *
 * @param props
 *  headers: { [key: string]: string}
 *  rows: { id: string, [key: string]: any} - value rows, keys have to match header keys for correct rendering.
 *  edit: { key: string, url: string } OPTIONAL.
 *  hideOnSmall: ['key'] - list of field keys to hide on small screens. OPTIONAL
 *  truncate: ['key'] - list of keys on which values should be truncated. OPTIONAL
 *
 *  NB! Keys in headers and rows object must match to display values correctly!
 *  NB! edit.link has to match format '\/*\/:id'
 * @constructor
 */
function ListTable(props: ListProps) {
  const columns = Object.keys(props.headers).length;
  const columnsOnSmall = props.hideOnSmall ? columns - props.hideOnSmall.length : columns;

  function isHidden(key: string) {
    return props.hideOnSmall ? props.hideOnSmall.includes(key) : false;
  }

  function getEditUrl(key: string, id: string) {
    if (props.edit && props.edit.key === key) {
      return props.edit.url.replace(':id', id);
    }
    return undefined;
  }

  return (
    <>
      {props.rows && (
        <Box display="flex" flexDirection="column" justifyItems="center">
          <TableHeader>
            {Object.keys(props.headers).map(header => {
              return (
                <HeaderCell
                  key={header}
                  columns={columns}
                  columnsOnSmall={columnsOnSmall}
                  title={props.headers[header]}
                  hideOnSmall={isHidden(header)}
                />
              );
            })}
          </TableHeader>

          {props.rows.map((row: RowData, index) => {
            return (
              <TableRow key={row.id} index={index}>
                {Object.keys(props.headers).map(key => {
                  return (
                    <TableCell
                      key={key}
                      columns={columns}
                      columnsOnSmall={columnsOnSmall}
                      hideOnSmall={isHidden(key)}
                      value={row[key]}
                      edit={getEditUrl(key, row.id)}
                    />
                  );
                })}
              </TableRow>
            );
          })}
        </Box>
      )}
    </>
  );
}

export default ListTable;

function TableHeader(props: { children: any }) {
  return (
    <Box className="border-bottom rounded-0">
      <Box display="flex" flexWrap="nowrap" justifyContent="space-around" my={2}>
        {props.children}
      </Box>
    </Box>
  );
}

function HeaderCell(props: { columns: number; columnsOnSmall: number; title: string; hideOnSmall: boolean }) {
  return (
    <Box
      width={[1 / props.columnsOnSmall, 1 / props.columns]}
      p={1}
      display={props.hideOnSmall ? ['none', 'block'] : 'block'}>
      <Text fontWeight="bold">{props.title}</Text>
    </Box>
  );
}

function TableRow(props: { index: number; children: any }) {
  return (
    <Box
      display="flex"
      flexWrap="nowrap"
      justifyContent="space-around"
      py={2}
      backgroundColor={props.index % 2 === 0 ? 'rgba(0, 0, 0, 0.05)' : 'inherit'}>
      {props.children}
    </Box>
  );
}

function TableCell(props: {
  columns: number;
  columnsOnSmall: number;
  hideOnSmall: boolean;
  value: any;
  edit?: string;
}) {
  return (
    <Box
      width={[1 / props.columnsOnSmall, 1 / props.columns]}
      px={2}
      py={1}
      display={props.hideOnSmall ? ['none', 'block'] : 'block'}>
      {props.edit ? (
        <Link to={props.edit}>
          <Text>{props.value}</Text>
        </Link>
      ) : (
        <Text>{props.value}</Text>
      )}
    </Box>
  );
}
